<template>
  <div> 
    <TypeDataTable
      v-for="infoTab in infoTabs" :key="infoTab.key"
      :headers="infoTab.headers"
      :items="infoTab.items"
      loading-text="Caricamento schede tecniche ..."
      :loading="loading"
      :show-select="false"
      :disable-pagination="true"
      item-key="value"
      :show-actions="false"
      :multiple="Array.isArray(infoTab.value)"
      headersColor="#b5e7a0"
      @rowClicked="checkboxClicked($event, infoTab)"
      class="biggerHeaderFont"
    >
     <template v-slot:custom-value="{ item }">
        <v-checkbox
          color="#b5e7a0"
          :input-value="checkBoxValue(item, infoTab)"
          @input="checkboxClicked(item, infoTab)"
        >
        </v-checkbox>
      </template>
    </TypeDataTable>
  </div>
</template>

<script>
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import formsDataSheet from './FormsDataSheetJson.js'
import customerForm from "@/services/customers/customer.form.js";

export default {
  name: 'barberHabits',
  components: {
    TypeDataTable,
  },
  data: function() {
    return {
      newForm: new formsDataSheet(),
      infoTabs: undefined,
      loading: false,
    }
  },
  props: {

    completed:{
      type: Boolean,
      default: false,
    },
  },
  mounted: function() {
    if(!customerForm.customer.barberDataSheet){
      customerForm.updateField("barberDataSheet", this.newForm.barberForm);
      this.infoTabs = [...this.newForm.barberForm.barberHabits]
    }
    else if(!!customerForm.customer.barberDataSheet && !!customerForm.customer.barberDataSheet.barberHabits){
      this.infoTabs = !!customerForm.customer.barberDataSheet.barberHabits.data ? [...customerForm.customer.barberDataSheet.barberHabits.data] : [...customerForm.customer.barberDataSheet.barberHabits]
    }
    this.updateCustomerForm()  
  },
  methods: {
    /**
     * Change checkbox value
     * @param {Object} row
     * @param {string} row.type
     * @param {number} row.value
     * @param {Object} infoTab
     * @param {Array} infoTab.headers
     * @param {Array} infoTab.items
     * @param {number} infoTab.key
     * @param {number|number[]} infoTab.value - the checkbox selected or multiselected
     */
    checkboxClicked(row, infoTab){
      if(typeof(infoTab.value) === 'number' || infoTab.value == undefined){
        (infoTab.value != row.value) ? infoTab.value = Number(row.value) : infoTab.value = undefined  
      }
      else if(Array.isArray(infoTab.value)){
        let find = infoTab.value.find(el => el == row.value)
        find == undefined ? infoTab.value = [...infoTab.value, row.value] : infoTab.value = infoTab.value.filter(el => el != row.value)
      }
      this.updateCustomerForm()
    },
    /**
     * Return the value of the checkbox
     * @param {Object} row
     * @param {string} row.type
     * @param {number} row.value
     * @param {Object} infoTab
     * @param {Array} infoTab.headers
     * @param {Array} infoTab.items
     * @param {number} infoTab.key
     * @param {number|number[]} infoTab.value - the checkbox selected or multiselected
     */
    checkBoxValue(row, infoTab){
      if(infoTab.value != undefined && row.value != undefined && typeof(infoTab.value) === 'number'){
        return infoTab.value == row.value
      }
      else if(infoTab.value != undefined && row.value != undefined && Array.isArray(infoTab.value)){
        let find = infoTab.value.find(el => el == row.value)
        return find != undefined
      }
      else 
        return false
    },
    /**
     * Update customer form Object
     */
    updateCustomerForm() {
      if (!!customerForm.customer.barberDataSheet && !!customerForm.customer.barberDataSheet.barberHabits) {
        customerForm.updateObjectField("barberDataSheet","barberHabits", this.infoTabs);
      } else if(!!customerForm.customer.barberDataSheet) {
        // Creation case
        customerForm.updateObjectField("barberDataSheet","barberHabits", this.infoTabs);
      }
      else {
        console.log('Error in updateLocalField');
      }
      this.checkAllCompleted()
    },
    /**
     * Check if all the fields are completed
     */
    checkAllCompleted(){
      let allCompleted = true
      for (const infoTab of this.infoTabs){ 
        if(infoTab.value == undefined || ((Array.isArray(infoTab.value) && infoTab.value.length == 0) || infoTab.value === "")){
          allCompleted = false
          break
        }   
      }
      this.$emit('update:completed', allCompleted)
    },
  },
  watch: {
  }
}
</script>

<style>
.biggerHeaderFont thead th {
  font-size: 20px !important;

 }
</style>
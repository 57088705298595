<template>
  <div>
    <div v-if="!noTitle" class="d-flex justify-center" style="margin-bottom: -13px">
      <v-alert :color="color" dense>{{ title }}</v-alert>
    </div>
    <table :style="cssProps">
      <tr>
        <th style="width: 20%">TONO</th>
        <th style="width: 40%; text-align:center;">
          GRAMMATURA {{noTitle ? title : ''}}
        </th>
        <th style="width: 40%; text-align:center" class="py-1">
          <ColorLinesAutocomplete
            :multiple="false"
            :clearable="false"
            v-model="localColorTypeId"
            @input="emitNewValue('colorTypeId', localColorTypeId)"
            :disabled="disabled"
            dense
          ></ColorLinesAutocomplete>
        </th>
      </tr>
      <tr v-for="(item) in localItems" :key="item.value" class="hoverColor">
        <td>
          <v-checkbox
            class="pl-2 pt-2"
            :color="color"
            :label="String(item.value + '.0')"
            :input-value="item.checked"
            @click="() => {
              item.checked = !item.checked;
              if(!item.checked){
                item.grammatura = ''
                item.colorType = ''
              }
              emitNewValue('items', localItems)
            }"
            :disabled="disabled || !localColorTypeId"
            dense
          ></v-checkbox>
        </td>
        <td>
          <v-textarea
            ref="grammatura"
            v-model="item.grammatura"
            hide-details="auto"
            @input="emitNewValue('items', localItems)"
            :rows="rowsTextArea"
            :color="color"
            :disabled="!item.checked || disabled"
            dense
            class="little-text"
            height="50"
            no-resize
          ></v-textarea>
        </td>
        <td>
          <v-textarea
            label="Note"
            single-line
            v-model="item.colorType"
            hide-details="auto"
            @input="emitNewValue('items', localItems)"
            :rows="rowsTextArea"
            :color="color"
            :disabled="!item.checked || disabled"
            dense
            class="little-text"
            height="50"
            no-resize
          ></v-textarea>
        </td>
      </tr>
      <tr>
        <td colspan="3">
          <div class="d-flex">
            <span class="black--text text-h6 pa-1 mt-1">OX</span>
            <div
              v-for="item in oxItems"
              :key="item"
              :class="[
                ...['pa-1','bottomSection','text-align-center'],
                localOxValues.includes(item) ? 'backGroundColor' : '',
              ]"
              @click="!disabled ? oxSelected(item) : () => {}"  
              style="width: 9%;"
            >
              <span class="text-h6 center">{{ item }}</span>
            </div>
            <v-text-field
              v-model="localInfoDown"
              :class="!!localInfoDown ? 'backGroundColor' : '' "
              label="Altro"
              filled
              dense
              solo
              flat
              color="white"
              class="pa-0 pt-1"
              hide-details="auto"
              @input="emitNewValue('infoDown', localInfoDown)"
              :disabled="disabled"
            ></v-text-field>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import ColorLinesAutocomplete from "@/components/common/ColorLinesAutocomplete.vue";

export default {
  name: "barberColoring",
  components: {
    TypeDataTable,
    ColorLinesAutocomplete,
  },
  data: function () {
    return {
      oxItems: [6, 9, 12.5, 15, 20, 25, 30, 35, 40],
      localItems: undefined,
      localInfoDown: undefined,
      localOxValues: [],
      localColorTypeId: undefined,
      rowsTextArea: 2,
    };
  },
  props: {
    title: {
      type: String,
      default: "COLORE CORONA",
    },
    color: {
      type: String,
      default: "red",
    },
    infoDown: {
      type: String,
      default: "red",
    },
    colorTypeId:{
      type: Number,
      default: undefined,
    },
    oxValues: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noTitle: {
      type: Boolean,
      default: false
    }
  },
  mounted: function () {
    this.localItems = [...this.items];
    this.localInfoDown = this.infoDown;
    this.localOxValues = [...this.oxValues];
    this.localColorTypeId = this.colorTypeId;
  },
  methods: {
    /**
     * @description Emit the event to update the data
     * @param {string} key
     * @param {any} value
     */
    emitNewValue(key, value) {
      this.$emit(key, value);
    },
    oxSelected(el){
      if(this.localOxValues.includes(el))
        this.localOxValues.splice(this.localOxValues.itemOf(el),1);
      else
        this.localOxValues = [el];  
      this.emitNewValue('oxValues',this.localOxValues)
    },
  },
  watch: {
    items(newVal) {
      this.localItems = [...newVal]
    },
    infoDown(newVal) {
      this.localInfoDown = newVal;
    },
    colorTypeId(newVal) {
      this.localColorTypeId = newVal
    },
    localInfoDown(newVal) {
      if(!!newVal)
        this.localOxValues = []
    },
    oxValues(newVal) {
      this.localOxValues = newVal;
    },
    localOxValues(newVal) {
      if(newVal.length > 0)
        this.localInfoDown = '';
    }
  },
  computed: {
    cssProps() {
      return {
        "--headers-color": this.color,
      };
    },
  },
};
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #dddddd;
}

td {
  text-align: left;
  border-right: 1px solid #dddddd;
  font-size: x-small !important;
}
th {
  text-align: left;
  padding: 8px;
  background-color: var(--headers-color) !important;
  border-right: 1px solid #dddddd;
}
tr {
  border-bottom: 1px solid #dddddd;
}
.hoverColor:hover {
  background-color: #f0efef;
}
.bottomSection:hover {
  background-color: var(--headers-color) !important;
  cursor: pointer;
}
.bottomSection {
  border: 1px solid #dddddd;
}
.center {
  display: table;
  margin: 0 auto;
  margin-top: 3px;
}
.backGroundColor {
  background-color: var(--headers-color) !important;
}
.notes {
  font-size:11px;
  margin-bottom: -16px
}

.little-text {
  font-size: 12px;
  line-height: 0.8rem !important;
}
</style>
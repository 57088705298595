var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.infoTabs),function(item,indexInfoTab){return _c('div',{key:item.key},[_c('div',{staticStyle:{"color":"red","font-size":"20px"}},[_vm._v(_vm._s(item.title)+" ")]),_c('div',{staticClass:"d-flex"},[_c('div',_vm._l((item.items),function(element,indexInfoTabElement){return _c('div',{key:element.key},[(element.type == 'openQuestion')?_c('OpenQuestion',{attrs:{"question":element.question,"value":element.value,"label":element.label},on:{"valueChanged":($event) => {
              _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].value = $event.value
              _vm.updateCustomerForm()
            }}}):(element.type == 'checkbox')?_c('CheckBoxQuestion',{attrs:{"value":element.value,"question":element.question,"checkboxes":element.checkboxes,"textfield":element.textfield},on:{"checkboxClicked":($event) => {
              _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].value = $event.value
              _vm.updateCustomerForm()
            },"textfieldChanged":($event) => {
              _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].textfield = $event
              _vm.updateCustomerForm()
            }}}):(element.type == 'table')?_c('TableQuestion',{attrs:{"items":element.value}}):_vm._e()],1)}),0),(_vm.infoTabs.length > 0 && indexInfoTab == 0)?_c('table',{staticStyle:{"width":"40%","margin-left":"100px","height":"40%","margin-top":"50px"}},[_c('tr',[_c('td',{staticStyle:{"width":"50%"}},[_c('v-img',{attrs:{"src":_vm.imagePath,"contain":"","gradient":"to bottom, rgba(0,0,0,0), rgba(0,0,0,0.2)","max-width":"20vw","max-height":"27vh"}})],1),_c('td',[_c('v-textarea',{attrs:{"auto-grow":"","label":"Note","color":"grey","rows":"9","hide-details":"auto"},on:{"input":_vm.updateCustomerForm},model:{value:(_vm.infoTabs[0].textField),callback:function ($$v) {_vm.$set(_vm.infoTabs[0], "textField", $$v)},expression:"infoTabs[0].textField"}})],1)])]):_vm._e()])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
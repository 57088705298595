import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';

class TreatementTypesService {


  list(page, rowPerPage) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/treatmentTypes/list", {
          page: page,
          rowPerPage: rowPerPage,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }

  create(treatmentType) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/treatmentTypes/create", treatmentType)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }

  update(treatmentType) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/treatmentTypes/update", treatmentType)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }

  delete(treatmentType) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!treatmentType.id) {
        reject("id undefined");
      }

      axios
        .get(apiUrl + "/registries/treatmentTypes/delete", {
          id: treatmentType.id,
        })
        .then((response) => {
          if (!response.success) {
            reject(response.results);
          } else {
            resolve(response);
          }
        });
    });
  }

  get(treatmentTypeId) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/registries/treatmentTypes/get", { id: treatmentTypeId })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        });
    });
  }

  fields() {
    return Promise.resolve([
      // here the fields
      { text: "Nome", value: "name" },
    ]);
  }
}

export default new TreatementTypesService();

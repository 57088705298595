var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.infoTabs),function(item,indexInfoTab){return _c('div',{key:item.key},[_c('div',{staticStyle:{"color":"red","font-size":"20px"}},[_vm._v(_vm._s(item.title)+" ")]),_vm._l((item.items),function(element,indexInfoTabElement){return _c('div',{key:element.key},[(element.type == 'openQuestion')?_c('OpenQuestion',{attrs:{"question":element.question,"value":element.value,"label":element.label},on:{"valueChanged":($event) => {
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].value = $event
          _vm.updateCustomerForm()
        }}}):(element.type == 'checkbox')?_c('CheckBoxQuestion',{attrs:{"value":element.value,"question":element.question,"label":element.label,"textfield":element.textfield,"checkboxes":element.checkboxes},on:{"checkboxClicked":($event) => {
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].value = $event.value
          _vm.updateCustomerForm()
        },"textfieldChanged":($event) => {
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].textfield = $event
          _vm.updateCustomerForm()
        }}}):(element.type == 'table')?_c('TableQuestion',{attrs:{"items":element.value}}):(element.type == 'bodyMeasures')?_c('TableBeautyBodyImage',{attrs:{"cmMeasures":element.cmMeasures,"arms":element.arms,"chest":element.chest,"waist":element.waist,"wrist":element.wrist,"flanks":element.flanks,"thigh":element.thigh,"knee":element.knee,"calf":element.calf,"ankle":element.ankle},on:{"cmMeasures":($event) => {
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures = $event
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].value = _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures 
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].arms
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].chest
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].waist
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].wrist
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].flanks
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].thigh
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].knee
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].calf
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].ankle ? true : undefined
          _vm.updateCustomerForm()
        },"arms":($event) => {
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].arms = $event
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].value = _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures 
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].arms
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].chest
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].waist
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].wrist
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].flanks
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].thigh
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].knee
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].calf
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].ankle ? true : undefined
          _vm.updateCustomerForm()
        },"chest":($event) => {
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].chest = $event
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].value = _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures 
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].arms
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].chest
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].waist
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].wrist
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].flanks
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].thigh
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].knee
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].calf
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].ankle ? true : undefined
          _vm.updateCustomerForm()
        },"waist":($event) => {
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].waist = $event
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].value = _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures 
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].arms
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].chest
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].waist
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].wrist
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].flanks
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].thigh
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].knee
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].calf
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].ankle ? true : undefined
          _vm.updateCustomerForm()
        },"wrist":($event) => {
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].wrist = $event
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].value = _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures 
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].arms
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].chest
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].waist
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].wrist
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].flanks
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].thigh
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].knee
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].calf
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].ankle ? true : undefined
          _vm.updateCustomerForm()
        },"flanks":($event) => {
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].flanks = $event
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].value = _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures 
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].arms
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].chest
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].waist
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].wrist
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].flanks
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].thigh
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].knee
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].calf
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].ankle ? true : undefined
          _vm.updateCustomerForm()
        },"thigh":($event) => {
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].thigh = $event
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].value = _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures 
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].arms
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].chest
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].waist
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].wrist
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].flanks
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].thigh
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].knee
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].calf
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].ankle ? true : undefined
          _vm.updateCustomerForm()
        },"knee":($event) => {
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].knee = $event
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].value = _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures 
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].arms
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].chest
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].waist
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].wrist
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].flanks
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].thigh
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].knee
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].calf
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].ankle ? true : undefined
          _vm.updateCustomerForm()
        },"calf":($event) => {
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].calf = $event
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].value = _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures 
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].arms
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].chest
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].waist
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].wrist
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].flanks
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].thigh
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].knee
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].calf
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].ankle ? true : undefined
          _vm.updateCustomerForm()
        },"ankle":($event) => {
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].ankle = $event
          _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].value = _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures 
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].arms
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].chest
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].waist
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].wrist
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].flanks
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].thigh
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].knee
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].calf
            && _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].ankle ? true : undefined
          _vm.updateCustomerForm()
        }}}):_vm._e()],1)})],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
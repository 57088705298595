import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class ColorChoicesService {
  list(page, rowPerPage) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/colorChoices/list", {
          page: page,
          rowPerPage: rowPerPage,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results)
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }

  create(colorChoice) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/colorChoices/create", colorChoice)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results)
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }

  update(colorChoice) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!colorChoice || !colorChoice.id) {
        reject(new Error("color choice not specified"));
      }

      axios
        .post(apiUrl + "/registries/colorChoices/update", colorChoice)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results)
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }

  delete(colorChoice) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!colorChoice || !colorChoice.id) {
        reject(new Error("color choice not specified"));
      }

      axios
        .get(apiUrl + "/registries/colorChoices/delete", {
          id: colorChoice.id,
        })
        .then((response) => {
          if (!response.success) {
            reject(response.results);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error)
        });
    });
  }

  get(colorChoiceId) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/registries/colorChoices/get", { id: colorChoiceId })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results)
          }
        })
        .catch((error) => {
          reject(error)
        });
    });
  }

  fields() {
    return Promise.resolve([
      { text: "Nome", value: "name" },
    ]);
  }
}

export default new ColorChoicesService();

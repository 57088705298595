import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import countryPrefixesCache from "./countryPrefixes.cache";

class countriesService {
  constructor() {
    this._alreadyLoaded = false
  }

  list(page, rowPerPage) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/registries/countries/list", {
          page: page,
          rowPerPage: rowPerPage,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }

  async listPrefixCached() {
    if (!this._alreadyLoaded) {
      await countryPrefixesCache.deleteAllCache()
      this._alreadyLoaded = true
    }

    if (await countryPrefixesCache.cachePresent()) {
      return await countryPrefixesCache.get()
    } else {
      await this.reloadCountriesPrefixCache()
      return await countryPrefixesCache.get()
    }
  }

  async reloadCountriesPrefixCache() {
    const countryPrefixes = await this.listPrefix(1, 1000)
    await countryPrefixesCache.cache({ countryPrefixes: countryPrefixes })
  }

  listPrefix(page, rowPerPage) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/registries/countries/listPrefix", {
          page: page,
          rowPerPage: rowPerPage,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }
}

export default new countriesService();
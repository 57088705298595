<template>
  <div>
    <div class="d-flex align-center">
      <div
        style="font-size:16px; min-width:20vw"
      >
        {{question}}
      </div>
      <v-checkbox v-for="(item, i) in localCheckBoxes" :key="i"
        class="pl-1"
        color="black"
        :label="String(item.text)"
        :input-value="localValue == item.value"
        @click="() => {
          emitNewValue('checkboxClicked',item)
        }"
        :disabled="disabled"
      ></v-checkbox>
      <v-text-field
        v-if="!!label"
        :label="label"
        :disabled="disabled"
        v-model="localTextfield"
        color="black"
        class="ml-3 mt-3"
        @input="($event) => {
          emitNewValue('textfieldChanged',localTextfield)
        }"
      ></v-text-field>
    </div>
  </div>
</template>
<script>

export default {
  name: "CheckBoxQuestion",
  components: {
  },
  data: function () {
    return {
      localCheckBoxes: [],
      localValue: undefined,
      localTextfield: undefined,
    };
  },
  props: {
    question: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false
    },
    checkboxes:{
      type: Array,
      default: () => []
    },
    value:{
      type: [Number, Array],
      default: undefined
    },
    textfield: {
      type: String,
      default: ""
    },
    label:{
      type: String,
      default: ""
    },
  },
  mounted: function () {
    this.localCheckBoxes = this.checkboxes
    this.localValue = this.value
    this.localTextfield = this.textfield
  },
  methods: {
    /**
     * @description Emit the event to update the data
     * @param {string} key
     * @param {any} value
     */
    emitNewValue(key, value) {
      if (value.value !== this.localValue)
        this.$emit(key, value);
      else {
        this.$emit(key, {
          ...value,
          value: null
        });
      }
    },
  },
  watch: {
    checkboxes(newVal){
      this.localCheckBoxes = newVal
    },
    value(newVal){
      this.localValue = newVal
    },
    textfield(newVal){
      this.localTextfield = newVal
    },
  }
};
</script>


<style scoped>
</style>
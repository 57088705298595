<template>
  <div>
    <div class="d-flex justify-center" style="margin-bottom: -13px">
      <v-alert :color="color" dense>{{title}}</v-alert>
    </div>
    <table :style="cssProps">
      <tr style="width: 30%">
        <th >
          <v-text-field 
            v-model="localInfoUp"
            @input="emitNewValue('infoUp',localInfoUp)"
            :disabled="disabled"
          ></v-text-field>
        </th>
      </tr>
      <tr>
        <td colspan="3" class="d-flex">
          <img
            :src="imagePath"
            position="left 50%"
            gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,0.2)"
            width="400px"
            style="object-fit: cover"
          >
          <div>
            <div class="pa-1" v-for="(item, i) in localItems" :key="item.value">
              <v-spacer style="width: 20vw"></v-spacer>
              <v-textarea 
                :height="localItems.length == 3 ? '130' : '100'"
                v-model="item.description" 
                filled 
                dense 
                hide-details="auto" 
                color="red" 
                :label="localItems.length == 3 ? String(i) : String(i + 1)"
                @input="emitNewValue('items',localItems)"
                :disabled="disabled"
              ></v-textarea>
            </div> 
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import TypeDataTable from "@/components/common/TypeDataTable.vue";

export default {
  name: "TableBarberLigthening",
  components: {
    TypeDataTable,
  },
  data: function () {
    return {
      localDate: undefined,
      localItems: undefined,
      localInfoUp: undefined,
      localOperatorId: undefined,
    };
  },
  props: {
    title:{
      type: String,
      default: 'COLORE CORONA'
    },
    color:{
      type: String,
      default: 'red'
    },
    imagePath:{
      type: String,
      default: ''
    },
    infoUp: {
      type: String,
      default: "red",
    },
    items: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted: function () {
    this.localItems = [...this.items];
  },
  methods: {
    /**
     * @description Emit the event to update the data
     * @param {string} key
     * @param {any} value
     */
    emitNewValue(key, value) {
      this.$emit(key, value);
    },
  },
  watch: {
    items(newVal) {
      this.localItems = [...newVal];
    },
    infoUp(newVal) {
      this.localInfoUp = newVal;
    },
  },
  computed: {
  cssProps() {
    return {
      '--headers-color': this.color,
    }
  }
  }
};
</script>


<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #dddddd;
}

td {
  text-align: left;
}
th {
  text-align: left;
  padding: 8px;
  background-color: var(--headers-color) !important;
}
tr {
  border-bottom: 1px solid #dddddd;
}
.hoverColor:hover {
  background-color: #f0efef;
}

</style>
<template>
  <div>
    <div class="d-flex align-center">
      <div
        style="font-size:16px"
      >
        {{question}}
      </div>
      <v-text-field
        v-model="localValue"
        :disabled="disabled"
        :label="label"
        color="black"
        class="ml-3 mt-3"
        @input="emitNewValue('valueChanged',localValue)"
      ></v-text-field>
    </div>
  </div>
</template>
<script>

export default {
  name: "OpenQuestion",
  components: {
  },
  data: function () {
    return {
      localValue: undefined,
    };
  },
  props: {
    question: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
  },
  mounted: function () {
    this.localValue = this.value
  },
  methods: {
    /**
     * @description Emit the event to update the data
     * @param {string} key
     * @param {any} value
     */
    emitNewValue(key, value) {
      this.$emit(key, value);
    },
  },
  watch: {
    value(newVal){
      this.localValue = newVal
    }
  }
};
</script>


<style scoped>
</style>
<template>
  <div>
    <div class="d-flex justify-end align-center">
      <v-tooltip allow-overflow bottom>
        <template v-slot:activator="{on, attrs}">
          <v-btn 
            icon 
            v-on="on" 
            v-bind="attrs"
            class="mx-2"
          >
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        <span>Mostra filtri</span>
      </v-tooltip>
      <v-tooltip allow-overflow bottom>
        <template v-slot:activator="{on, attrs}">
          <v-btn 
            icon 
            v-on="on" 
            v-bind="attrs"
            class="mx-2"
            :disabled="!selectedDataSheet || selectedDataSheet.length == 0"
            @click="duplicateDataSheet"
          >
            <v-icon>mdi-checkbox-multiple-blank-outline</v-icon>
          </v-btn>
        </template>
        <span>Duplica scheda</span>
      </v-tooltip>
      <v-tooltip allow-overflow bottom>
        <template v-slot:activator="{on, attrs}">
          <v-btn 
            icon 
            v-on="on" 
            v-bind="attrs"
            class="mx-2"
            @click="openDialog"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Aggiungi scheda</span>
      </v-tooltip>
    </div>
    <TypeDataTable
      v-model="selectedDataSheet"
      :headers="headers"
      :items="dataSheets"
      loading-text="Caricamento schede tecniche ..."
      :translator="(fieldName, fieldValue) => {
        if(fieldName == 'operator' && !!fieldValue) return fieldValue.firstname + ' ' + fieldValue.lastname
      }"
      :loading="loading"
      :page.sync="page"
      :row-per-page.sync="rowPerPage"
      :total-pages.sync="totalPages"
      :show-select="false"
      disbale-pagination-on-loading
      @edit="handleEdit"
      @delete="deleteDataSheet"
    ></TypeDataTable>
    <StandardDialog
      v-model="newFormDialog"
      :dialog-height="null"
      title="Nuova scheda tecnica"
      :persistent="loadingCreate"
    >
      <BarberDataSheetsForm
        v-model="newDataSheet"
      ></BarberDataSheetsForm>
      <template v-slot:footer-actions>
        <v-btn text @click="newFormDialog = false" :disabled="loadingCreate" color="error">
          Chiudi
        </v-btn>
        <v-btn text @click="createDataSheet" :loading="loadingCreate" color="default">
          Salva
        </v-btn>
      </template>
    </StandardDialog>
    <StandardDialog
      v-model="editFormDialog"
      :dialog-height="null"
      title="Modifica scheda tecninca"
      :persistent="loadingUpdate"
    >
      <BarberDataSheetsForm
        v-model="editDataSheet"
      ></BarberDataSheetsForm>
      <template v-slot:footer-actions>
        <v-btn text @click="editFormDialog = false" :disabled="loadingUpdate" color="error">
          Chiudi
        </v-btn>
        <v-btn text @click="updateDataSheet" :loading="loadingUpdate" color="default">
          Salva
        </v-btn>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import StandardDialog from '@/components/common/StandardDialog.vue'
import BarberDataSheetsForm from './BarberDataSheetsForm.vue'
import dataSheetService from '@/services/dataSheets/dataSheets.service.js'
import { cloneDeep } from "lodash"

export default {
  name: 'BarberDataSheetsList',
  components: {
    TypeDataTable,
    StandardDialog,
    BarberDataSheetsForm
  },
  data: function() {
    return {
      dataSheets: [],
      headers: [],
      loading: false,
      page: 1,
      rowPerPage: 20,
      totalPages: 100,
      newFormDialog: false,
      newDataSheet: {},
      selectedDataSheet: undefined,
      loadingCreate: false,
      editDataSheet: { },
      editFormDialog: false,
      loadingUpdate: false,
      oldEditDataSheet: undefined,
    }
  },
  props: {
    customer: {}
  },
  mounted: function() {
    this.loadHeaders().then(() => {
      this.loadDataSheets()
    })
  },
  methods: {
    async loadHeaders() {
      this.headers = await dataSheetService.headers('barber')
    },
    loadDataSheets() {
      this.loading = true
      dataSheetService.list(this.page, this.rowPerPage, {
        type: 'barber',
        customer: this.customer
      }).then((results) => {
        if(this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if(this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if(this.totalPages < this.page) {
          this.page = this.totalPages
        }
        
        this.dataSheets = results.rows
        this.loading = false
      })
    },
    openDialog() {
      this.newFormDialog = true
    },
    handleEdit(dataSheet) {
      if(!!dataSheet.operatorId) dataSheet.operatorId = parseInt(dataSheet.operatorId)
      this.editDataSheet = cloneDeep(dataSheet)
      this.editFormDialog = true
    },
    createDataSheet() {
      this.loadingCreate = true
      this.newDataSheet['type'] = 'barber'
      dataSheetService.create(this.customer, this.newDataSheet).then(() => {
        this.newDataSheet = {}
        this.newFormDialog = false
        this.loadingCreate = false
        this.loadDataSheets()
      })
    },
    updateDataSheet() {
      this.loadingUpdate = true
      dataSheetService.update(this.editDataSheet).then(() => {
        this.editFormDialog = false
        this.loadingUpdate = false
        this.loadDataSheets()
      })
    },
    deleteDataSheet(dataSheet) {
      let confirmed = confirm('Sei sicuro di volere eliminare la scheda tecnica?')

      if(confirmed) {
        dataSheetService.delete(dataSheet).then(() => {
          this.loadDataSheets()
        })
      }
    },
    duplicateDataSheet() {
      this.loadingCreate = true
      let duplicatedDataSheet = this.selectedDataSheet[0];
      delete duplicatedDataSheet.id
      delete duplicatedDataSheet.createdAt
      dataSheetService.create(this.customer, duplicatedDataSheet).then(() => {
        this.loadingCreate = false
        this.loadDataSheets()
      })
    },
  },
  watch: {
    page() {
      this.loadDataSheets()
    },
    rowPerPage() {
      this.loadDataSheets()
    },
  }
}
</script>

<style>

</style>
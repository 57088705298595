<template>
  <div class="pa-0" style="overflow-y: scroll; height: calc(100vh - 64px - 48px - 48px - 30px)"> 
    <div class="stick-title">
      <v-tabs color="grey" v-model="tab">
        <v-tab v-for="element in customerTabs" 
          :key="element.key" 
          :class="element.completed ? 'tabCompleted' : 'tabUncompleted'"
          :style="element.key == tab ? 'background-color: #E8E8E8;' : ''"
        >{{ element.title }}</v-tab>
        <v-tabs-slider color="black2" ></v-tabs-slider>
      </v-tabs>
      <v-divider></v-divider>
    </div>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="tabItem in customerTabs" :key="tabItem.key" eager>
        <v-card flat>
          <component :completed.sync="tabItem.completed" class="pa-1" :is="tabItem.component"></component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import * as formComponents from "./";
import BarberInfoDataSheet from "../dataSheetsLastVersion/BarberInfoDataSheet.vue";
import BarberExpectationsDataSheet from "../dataSheetsLastVersion/BarberExpectationsDataSheet.vue";
import BarberHabitsDataSheet from "../dataSheetsLastVersion/BarberHabitsDataSheet.vue";
import BarberHairCareDataSheet from "../dataSheetsLastVersion/BarberHairCareDataSheet.vue";
import BarberColoringDataSheet from "../dataSheetsLastVersion/BarberColoringDataSheet.vue";
import customerForm from "@/services/customers/customer.form.js";

export default {
  name: "BarberDataSheet",
  components: {
    ...formComponents,
    BarberInfoDataSheet,
    BarberExpectationsDataSheet,
    BarberHabitsDataSheet,
    BarberHairCareDataSheet,
    BarberColoringDataSheet,
  },
  data: function () {
    return {
      tab: null,
      infoTabs: undefined,
      customerTabs: [
        {
          key: 0,
          title: "Info Base",
          name: "barberInfo",
          component: "BarberInfoDataSheet",
          completed: false,
        },
        {
          key: 1,
          title: "Aspettative e Sensazioni",
          name: "barberExpectations",
          component: "BarberExpectationsDataSheet",
          completed: false,
        },
        {
          key: 2,
          title: "Abitudini a casa",
          name: "barberHabits",
          component: "BarberHabitsDataSheet",
          completed: false,
        },
        {
          key: 3,
          title: "Abitudini in store",
          name: "barberHairCare",
          component: "BarberHairCareDataSheet",
          completed: false,
        },
        {
          key: 4,
          title: "COLORAZIONE",
          name: "barberColoring",
          component: "BarberColoringDataSheet",
          completed: false,
        },
      ],
    };
  },
  props: {
    tabName: {
      type: String,
      default: 'barberInfo'
    },
  },
  mounted: function () {
    this.changeTabBasedOnProp(this.tabName)

    if(!!customerForm.customer){
      this.infoTabs = customerForm.customer.barberDataSheet
    } 
    if(!!this.infoTabs){
      for (const element of Object.entries(this.infoTabs)) {
        try {
          this.checkAllCompleted(element)  
        } catch (error) {
          console.log(error)
        }
      }
    }
    customerForm.on('update', function() {
      if(!!customerForm.customer && !!customerForm.customer.barberDataSheet) {
        this.infoTabs = customerForm.customer.barberDataSheet
        for (const element of Object.entries(this.infoTabs)) {
          try {
            if(!!element[1].data)
              this.checkAllCompleted(element)  
          } catch (error) {
            console.log(error)
          }
        }
      }
    }.bind(this))
  },
  methods: {
    /**
     * Check if all the fields are completed
     * @param {Object} infoTab - The info tab to check
     */
    checkAllCompleted(infoTab){
      if(infoTab[0] == 'version' || infoTab[0] == 'id' || infoTab[0] == 'customerId' || infoTab[0] == 'createdAt')
        return
      else if(infoTab[0] == 'barberColoring' && !!infoTab[1].data){
        let completed = !!infoTab[1].data[0].date
        if(completed){
          let customerTabToUpdate = this.customerTabs.find(el => el.name == infoTab[0])
          if(!!customerTabToUpdate) customerTabToUpdate.completed = true
        }
      }  
      else if(!!infoTab[1].data){
        let completed = true
        for (const el of infoTab[1].data){ 
          if(el.value == undefined || (Array.isArray(el.value) && el.value.length == 0)){
            completed = false
            break  
          }   
        }
        if(completed){
          let customerTabToUpdate = this.customerTabs.find(el => el.name == infoTab[0])
          if(!!customerTabToUpdate) customerTabToUpdate.completed = true
        }
      }
    },

    changeTabBasedOnProp(tabName) {
      for (let i = 0; i < this.customerTabs.length; i++) {
        if (this.customerTabs[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
  },
};
</script>

<style>
.v-tab.tabUncompleted {
  color: red !important;
}
.v-tab.tabCompleted {
  color: green !important;
}
.stick-title {
  position: sticky;
  top: 0;
  z-index: 5;
}
</style>
<template>
  <div class="d-flex align-center flex-column pt-5">
    <table style="width: 100%">
      <tr>
        <td style="width: 30%">
          <v-img
            :src="require('@/assets/nails.jpg')"
            contain
            gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,0.2)"
            max-width="30vw"
            min-height="21vh"
          > 
          </v-img>
        </td>  
        <td>
          <table style="width:100%;" v-if="!!infoTabs[0]">
            <tr>
              <th>
                Mese
              </th>
              <th>
                Note
              </th>
            </tr>
            <tr v-for="n in Object.keys(infoTabs[0].items[0].value)">
              <td>
                <v-text-field v-model="infoTabs[0].items[0].value[n].month" @input="updateCustomerForm"></v-text-field>
              </td>
              <td>
                <v-text-field v-model="infoTabs[0].items[0].value[n].notes" @input="updateCustomerForm"></v-text-field>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <table class="mt-5">
      <tr v-if="!!infoTabs[1]">
        <th v-for="item in infoTabs[1].items" :key="'label_' + item.key">
          {{item.label}}
        </th>
      </tr>
      <tr v-if="!!infoTabs[1]">
        <td v-for="item in infoTabs[1].items" :key="'value_' + item.key">
          <v-textarea v-model="item.value" style="min-width: 20vw; height: 100%;" @input="updateCustomerForm"></v-textarea>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import formsDataSheet from './FormsDataSheetJson.js'
import customerForm from "@/services/customers/customer.form.js";

export default {
  name: 'BeautyExtentionDataSheet',
  components: {
  },
  data: function() {
    return {
      newForm: new formsDataSheet(),
      infoTabs: [],
      loading: false,
    }
  },
  props: {
    completed:{
      type: Boolean,
      default: false,
    },
  },
  mounted: function() {
    if(!customerForm.customer.beautyDataSheet || !customerForm.customer.beautyDataSheet.beautyNails){
      this.infoTabs = [...this.newForm.beautyForm.beautyNails]
    }
    else if(!!customerForm.customer.beautyDataSheet && !!customerForm.customer.beautyDataSheet.beautyNails){
      this.infoTabs = !!customerForm.customer.beautyDataSheet.beautyNails.data ? [...customerForm.customer.beautyDataSheet.beautyNails.data] : [...customerForm.customer.beautyDataSheet.beautyNails]
    }

    this.checkAllCompleted()
  },
  methods: {
    /**
     * Update customer form Object
     */
    updateCustomerForm() {
      if (!!customerForm.customer.beautyDataSheet && !!customerForm.customer.beautyDataSheet.beautyNails) {
        customerForm.updateObjectField("beautyDataSheet","beautyNails", this.infoTabs);
      } else if(!!customerForm.customer.beautyDataSheet) {
        // Creation case
        customerForm.updateObjectField("beautyDataSheet","beautyNails", this.infoTabs);
      }
      else {
        console.log('Error in updateLocalField');
      }
      this.checkAllCompleted()
    },
    /**
     * Check if all the fields are completed
     */
    checkAllCompleted(){
      let completed = false

      completed = completed || Object.keys(this.infoTabs[0].items[0].value).some(key => this.infoTabs[0].items[0].value[key].month !== "" || this.infoTabs[0].items[0].value[key].notes !== "")
      completed = completed || this.infoTabs[1].items.some(item => item.value !== "")

      this.$emit('update:completed', completed)
    },
  },
  watch: {
  }
}
</script>

<style scoped>
table, th, td {
  border: 1px solid black;
}
</style>
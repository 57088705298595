var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.noTitle)?_c('div',{staticClass:"d-flex justify-center",staticStyle:{"margin-bottom":"-13px"}},[_c('v-alert',{attrs:{"color":_vm.color,"dense":""}},[_vm._v(_vm._s(_vm.title))])],1):_vm._e(),_c('table',{style:(_vm.cssProps)},[_c('tr',[_c('th',{staticStyle:{"width":"20%"}},[_vm._v("TONO")]),_c('th',{staticStyle:{"width":"40%","text-align":"center"}},[_vm._v(" GRAMMATURA "+_vm._s(_vm.noTitle ? _vm.title : '')+" ")]),_c('th',{staticClass:"py-1",staticStyle:{"width":"40%","text-align":"center"}},[_c('ColorLinesAutocomplete',{attrs:{"multiple":false,"clearable":false,"disabled":_vm.disabled,"dense":""},on:{"input":function($event){return _vm.emitNewValue('colorTypeId', _vm.localColorTypeId)}},model:{value:(_vm.localColorTypeId),callback:function ($$v) {_vm.localColorTypeId=$$v},expression:"localColorTypeId"}})],1)]),_vm._l((_vm.localItems),function(item){return _c('tr',{key:item.value,staticClass:"hoverColor"},[_c('td',[_c('v-checkbox',{staticClass:"pl-2 pt-2",attrs:{"color":_vm.color,"label":String(item.value + '.0'),"input-value":item.checked,"disabled":_vm.disabled || !_vm.localColorTypeId,"dense":""},on:{"click":() => {
            item.checked = !item.checked;
            if(!item.checked){
              item.grammatura = ''
              item.colorType = ''
            }
            _vm.emitNewValue('items', _vm.localItems)
          }}})],1),_c('td',[_c('v-textarea',{ref:"grammatura",refInFor:true,staticClass:"little-text",attrs:{"hide-details":"auto","rows":_vm.rowsTextArea,"color":_vm.color,"disabled":!item.checked || _vm.disabled,"dense":"","height":"50","no-resize":""},on:{"input":function($event){return _vm.emitNewValue('items', _vm.localItems)}},model:{value:(item.grammatura),callback:function ($$v) {_vm.$set(item, "grammatura", $$v)},expression:"item.grammatura"}})],1),_c('td',[_c('v-textarea',{staticClass:"little-text",attrs:{"label":"Note","single-line":"","hide-details":"auto","rows":_vm.rowsTextArea,"color":_vm.color,"disabled":!item.checked || _vm.disabled,"dense":"","height":"50","no-resize":""},on:{"input":function($event){return _vm.emitNewValue('items', _vm.localItems)}},model:{value:(item.colorType),callback:function ($$v) {_vm.$set(item, "colorType", $$v)},expression:"item.colorType"}})],1)])}),_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"black--text text-h6 pa-1 mt-1"},[_vm._v("OX")]),_vm._l((_vm.oxItems),function(item){return _c('div',{key:item,class:[
              ...['pa-1','bottomSection','text-align-center'],
              _vm.localOxValues.includes(item) ? 'backGroundColor' : '',
            ],staticStyle:{"width":"9%"},on:{"click":function($event){!_vm.disabled ? _vm.oxSelected(item) : () => {}}}},[_c('span',{staticClass:"text-h6 center"},[_vm._v(_vm._s(item))])])}),_c('v-text-field',{staticClass:"pa-0 pt-1",class:!!_vm.localInfoDown ? 'backGroundColor' : '',attrs:{"label":"Altro","filled":"","dense":"","solo":"","flat":"","color":"white","hide-details":"auto","disabled":_vm.disabled},on:{"input":function($event){return _vm.emitNewValue('infoDown', _vm.localInfoDown)}},model:{value:(_vm.localInfoDown),callback:function ($$v) {_vm.localInfoDown=$$v},expression:"localInfoDown"}})],2)])])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./BeautyWorksDataSheet.vue?vue&type=template&id=01a5ed58&scoped=true"
import script from "./BeautyWorksDataSheet.vue?vue&type=script&lang=js"
export * from "./BeautyWorksDataSheet.vue?vue&type=script&lang=js"
import style0 from "./BeautyWorksDataSheet.vue?vue&type=style&index=0&id=01a5ed58&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01a5ed58",
  null
  
)

export default component.exports
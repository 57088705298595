<template>
  <div class="py-1">
    <table>
      <tr>
        <th v-for="(element, index) in localItems" :key="index"
          style="color: red;"
        >{{element.text}}</th>
      </tr>
      <tr>
        <td v-for="(element, index) in localItems" :key="index">
          <v-textarea
            hide-details="auto"
            v-model="element.value"
            :disabled="disabled"
            color="balck"
            rows="3"
            @input="emitNewValue('valueChanged',localItems)"
          ></v-textarea>  
        </td>
      </tr>
    </table>
  </div>
</template>
<script>

export default {
  name: "TableQuestion",
  components: {
  },
  data: function () {
    return {
      localItems: []
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  mounted: function () {
    this.localItems = this.items
  },
  methods: {
    /**
     * @description Emit the event to update the data
     * @param {string} key
     * @param {any} value
     */
    emitNewValue(key, value) {
      this.$emit(key, value);
    },
  },
};
</script>


<style scoped>
table, th, td {
  border: 1px solid black;
}
table {
  width: 100%
}
</style>
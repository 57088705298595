import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class PmuMethodsService {
  list(page, rowPerPage) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/pmuMethods/list", {
          page: page,
          rowPerPage: rowPerPage,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results)
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }

  create(pmuMethod) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/pmuMethods/create", pmuMethod)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results)
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }

  update(pmuMethod) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!pmuMethod || !pmuMethod.id) {
        reject(new Error("needle choice not specified"));
      }

      axios
        .post(apiUrl + "/registries/pmuMethods/update", pmuMethod)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results)
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }

  delete(pmuMethod) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!pmuMethod || !pmuMethod.id) {
        reject(new Error("needle choice not specified"));
      }

      axios
        .get(apiUrl + "/registries/pmuMethods/delete", {
          id: pmuMethod.id,
        })
        .then((response) => {
          if (!response.success) {
            reject(response.results);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error)
        });
    });
  }

  get(pmuMethodId) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/registries/pmuMethods/get", { id: pmuMethodId })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results)
          }
        })
        .catch((error) => {
          reject(error)
        });
    });
  }

  fields() {
    return Promise.resolve([
      { text: "Nome", value: "name" },
    ]);
  }
}

export default new PmuMethodsService();

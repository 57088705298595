<template>
  <!-- width 680, height 110-->
  <canvas 
    id="singatureCanvas" 
    width="580" 
    height="100" 
    @mousedown="mousedown" 
    @mousemove="mousemove"
    @mouseup="sign = false" 
    @mouseout="sign = false" 
    />
</template>

<style scoped>
canvas {
  border: 1px solid black;
  background-color: white;
  cursor: crosshair;
}
</style>

<script>

import Vue from "vue";
import customersService from '@/services/customers/customers.service.js';

export default {
  name: "SignatureCanvas",
  props: {
    modelValue: {
      type: null
    },
    bus: {
      default: () => {
        return new Vue
      }
    },
    customerId: {
      default: undefined
    }
  },
  mounted() {
    this.bus.$on('clearSignature', this.clearSignature)
    this.bus.$on('saveSignature', this.saveSignature)
    this.ctx = this.$el.getContext('2d')
    this.ctx.strokeStyle = 'black'
    this.ctx.lineWidth = 2
  },
  data() {
    return {
      ctx: null,
      sign: false,
      prevX: 0,
      prevY: 0,
      img: undefined
    }
  },
  methods: {
    mousedown($event) {
      this.sign = true
      this.prevX = $event.offsetX
      this.prevY = $event.offsetY
    },
    mousemove($event) {
      if (this.sign) {
        const currX = $event.offsetX
        const currY = $event.offsetY
        this.draw(this.prevX, this.prevY, currX, currY)
        this.prevX = currX
        this.prevY = currY
      }
    },
    draw(depX, depY, destX, destY) {
      this.ctx.beginPath()
      this.ctx.moveTo(depX, depY)
      this.ctx.lineTo(destX, destY)
      this.ctx.closePath()
      this.ctx.stroke()

      //this.img = this.$el.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    },
    clearSignature() {
      this.ctx.clearRect(0, 0, this.$el.width, this.$el.height)
    },
    async saveSignature(checks) {
      const canvas = document.getElementById("singatureCanvas")
      canvas.download = "SignatureTmp.png"

      var data = new FormData()
      let imageBlob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));
      data.append("signature", imageBlob, 'signature.png')
      data.append('checkRule', checks[0])
      data.append('checkMarketing', checks[1])
      data.append('checkThird', checks[2])
      data.append('checkAnalytics', checks[3])

      customersService.uploadSignature(this.customerId, data)
      this.ctx.clearRect(0, 0, this.$el.width, this.$el.height)
      //this.bus.$emit('send-signature', this.img)
    }
  },
}
</script>
<template>
  <TypeDataTable
    :headers="localHeaders"
    :items="localItems"
    :showSelect="false"
    :showActions="false"
    :loading="loading"
    :disablePagination="true"
  >
    <template v-slot:custom-date="{ item }">
      <DateTimeFormattor :value="item.bill.openedAt"> </DateTimeFormattor>
    </template>
    <template v-slot:custom-operator="{ item }">
      {{ item.bill.type != 'bar' ?
        item.billServiceOperators
          .map((bso) => {
            return bso.operator.firstname + " " + bso.operator.lastname;
          })
          .join(", ")
          : 'Operatore BAR'
      }}
    </template>
    <template v-slot:custom-service="{ item }">
      {{item.service ? item.service.name : 'Servizio non trovato - ID: ' + item.serviceId}}
    </template>
    <template v-slot:custom-cashdesk="{ item }">
      <span v-if="!!item.bill.paymentTransactions[0] && !!item.bill.paymentTransactions[0].paymentTransactionsCashDeskLogs && !!item.bill.paymentTransactions[0].paymentTransactionsCashDeskLogs.cashDeskLog">
        {{item.bill.paymentTransactions[0].paymentTransactionsCashDeskLogs.cashDeskLog.operator.firstname + " " + item.bill.paymentTransactions[0].paymentTransactionsCashDeskLogs.cashDeskLog.operator.lastname}}
      </span>
    </template>
  </TypeDataTable>
</template>

<script>
import customerForm from "@/services/customers/customer.form.js";
import diaryCustomer from "@/services/diaryCustomer/diaryCustomer.service.js";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import DateTimeFormattor from "@/components/common/DateTimeFormattor.vue";

export default {
  name: "DiaryFormTab",
  components: {
    TypeDataTable,
    DateTimeFormattor,
  },
  data: function () {
    return {
      localHeaders: [
        { text: "Effettuato", value: "date", type: "custom" },
        {
          text: "Descrizione della Prestazione Effettuata",
          value: "service",
          type: "custom",
        },
        { text: "Operatore", value: "operator", type: "custom" },
        { text: "Cassa aperta da", value: "cashdesk", type: "custom"}
      ],
      localItems: [],
      selectedCustomerId: undefined,
      loading: true,
    };
  },
  props: {
    canViewGhost: {
      type: Boolean,
      default: false,
    },
  },
  mounted: function () {
    this.updateCustomerId();
    customerForm.on("update", this.updateCustomerId);
    this.$once("hook:beforeDestroy", function () {
      customerForm.off("update", this.updateCustomerId);
    });
  },
  methods: {
    updateCustomerId() {
      if (
        this.selectedCustomerId != customerForm.customer.id &&
        !!customerForm.customer.id
      ) {
        this.loading = true
        this.selectedCustomerId = customerForm.customer.id;
        this.fetchDiary().then((result) => {
          this.localItems = result;
          this.loading = false
        });
      }
    },
    fetchDiary() {
      return new Promise((resolve, reject) => {
        diaryCustomer.getData(this.selectedCustomerId, this.canViewGhost).then((result) => {
          resolve(result);
        });
      });
    },
  },
  watch: {
    canViewGhost: function (newVal, oldVal) {
      this.fetchDiary().then((result) => {
          this.localItems = result;
          this.loading = false
        });
    },
  },
};
</script>

<style>
</style>
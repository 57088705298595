<template>
  <div>
    <v-form>
      <v-row class="my-1 ml-3">
        <v-col>
          <v-row class="mb-1 ml-3">
            <v-text-field
              v-model="meds"
              prepend-icon="mdi-medical-bag"
              filled
              dense
              :rules="[presenceRule]"
              label=" Farmaci Assunti"
              hide-details="auto"
              @input="updateLocalField('meds')"
            ></v-text-field>
          </v-row>
          <v-row class="mb-1 ml-3">
            <v-text-field
              v-model="allergies"
              prepend-icon="mdi-pill"
              :rules="[presenceRule]"
              filled
              dense
              label=" Allergie"
              hide-details="auto"
              @input="updateLocalField('allergies')"
            ></v-text-field>
          </v-row>
          <v-row class="mb-1 ml-3">
            <v-text-field
              v-model="pathologies"
              prepend-icon="mdi-needle"
              :rules="[presenceRule]"
              filled
              dense
              label=" Patologie Gravi"
              hide-details="auto"
              @input="updateLocalField('pathologies')"
            ></v-text-field>
          </v-row>
          <v-row class="mb-1 ml-3">
            <v-text-field
              v-model="skinScarring"
              prepend-icon="mdi-human-handsdown"
              :rules="[presenceRule]"
              filled
              dense
              label=" Cicatrizzazione Della Pelle"
              hide-details="auto"
              @input="updateLocalField('skinScarring')"
            ></v-text-field>
          </v-row>
        </v-col>
        <v-col>
          <v-row class="mb-1 ml-3">
            <v-text-field
              v-model="herpes"
              prepend-icon="mdi-bandage"
              :rules="[presenceRule]"
              filled
              dense
              label=" Herpes"
              hide-details="auto"
              @input="updateLocalField('herpes')"
            ></v-text-field>
          </v-row>
          <v-row class="mb-1 ml-3">
            <v-text-field
              v-model="skinCare"
              prepend-icon="mdi-face-woman-shimmer-outline"
              :rules="[presenceRule]"
              filled
              dense
              label=" Trattamenti Estetici sul Viso"
              hide-details="auto"
              @input="updateLocalField('skinCare')"
            ></v-text-field>
          </v-row>
          <v-row class="mb-1 ml-3">
            <v-text-field
              v-model="skinAlterations"
              prepend-icon="mdi-emoticon-sick-outline"
              :rules="[presenceRule]"
              filled
              dense
              label=" Alterazioni Cutanee"
              hide-details="auto"
              @input="updateLocalField('skinAlterations')"
            ></v-text-field>
          </v-row>
          <v-row class="ml-3">
            <Datepicker
              v-model="dataProcessing"
              label="Autorizzazione trattamento dati"
              @input="updateLocalField('dataProcessing')"
              :filled="true"
            ></Datepicker>
          </v-row>
        </v-col>
      </v-row>
      <div>
        <v-row class="ml-5">
          <v-checkbox
            v-model="pregnant"
            label="In Gravidanza"
            class="ml-3"
            @click="updateLocalField('pregnant')"
          ></v-checkbox>
          <v-checkbox
            v-model="diabetes"
            label="Soffre Di Diabete"
            class="ml-3"
            @click="updateLocalField('diabetes')"
          ></v-checkbox>
          <v-checkbox
            v-model="sendBySms"
            label="Autorizza Invio SMS Promozionali"
            class="ml-3"
            @click="updateLocalField('sendBySms')"
          ></v-checkbox>
          <v-checkbox
            v-model="sendByEmail"
            label="Autorizza Invio Email Promozionali"
            class="ml-3"
            @click="updateLocalField('sendByEmail')"
          ></v-checkbox>
        </v-row>
      </div>
    </v-form>
  </div>
</template>

<script>
import customerForm from "@/services/customers/customer.form.js";
import { Datepicker } from "likablehair-ui-components";

export default {
  name: "HealthInfoFormTab",
  components: {
    Datepicker,
  },
  data: function () {
    return {
      meds: undefined,
      allergies: undefined,
      pathologies: undefined,
      skinScarring: undefined,
      herpes: undefined,
      skinCare: undefined,
      skinAlterations: undefined,
      dataProcessing: undefined,
      pregnant: undefined,
      diabetes: undefined,
      sendBySms: undefined,
      sendByEmail: undefined,
      infoToSend: {},
      presenceRule: (v) => {
        if (v) {
          return v.length <= 255 || "Al massimo 255 caratteri";
        } else {
          return true;
        }
      },
    };
  },
  mounted: function () {
    let updateHandler = function (data) {
      if (!!customerForm.customer.infoSanitary) {
        this.infoToSend.id = customerForm.customer.infoSanitary.id;
        let attributesToUpdate = [
          "meds",
          "allergies",
          "pathologies",
          "skinScarring",
          "herpes",
          "skinCare",
          "skinAlterations",
          "pregnant",
          "diabetes",
          "sendBySms",
          "sendByEmail",
        ];
        attributesToUpdate.forEach((nameAttribute) => {
          this[nameAttribute] =
            customerForm.customer.infoSanitary[nameAttribute];
          this.infoToSend[nameAttribute] =
            customerForm.customer.infoSanitary[nameAttribute];
        });
        //Check dataProcessing outside, so i dont't check every loop dateProcessing, because i have to create a date from string
        if (!!customerForm.customer.infoSanitary["dataProcessing"]) {
          this["dataProcessing"] = new Date(
            customerForm.customer.infoSanitary["dataProcessing"]
          );
          this.infoToSend["dataProcessing"] = new Date(
            customerForm.customer.infoSanitary["dataProcessing"]
          );
        }
      }
    };
    updateHandler = updateHandler.bind(this);
    updateHandler();
    customerForm.on("update", updateHandler);
    this.$once("hook:beforeDestroy", function () {
      customerForm.off("update", updateHandler);
    });
  },
  methods: {
    updateLocalField(field) {
      if (!!customerForm.customer.infoSanitary) {
        //Update Case
        this.infoToSend[field] = this[field];
        customerForm.updateField("infoSanitary", this.infoToSend);
      } else {
        // Creation case
        customerForm.updateField("infoSanitary", {});
        this.infoToSend[field] = this[field];
        customerForm.updateField("infoSanitary", this.infoToSend);
      }
    },
  },
  watch: {},
};
</script>

<style>
</style>
<template>
  <div class="d-flex align-center" style="gap: 25px">
    <div style="width: 35%">
      <div v-for="(item, indexInfoTab) in infoTabs" :key="item.key">
        <div
          style="color: red; font-size: 20px"
        >{{item.title}}
        </div>
        <div v-for="(element, indexInfoTabElement)  in item.items" :key="element.key">
          <OpenQuestion
            v-if="element.type == 'openQuestion'"
            :question="element.question"
            :value="element.value"
            :label="element.label"
            @valueChanged="($event) => {
              infoTabs[indexInfoTab].items[indexInfoTabElement].value = $event
              updateCustomerForm()
            }"
          ></OpenQuestion>  
            <CheckBoxQuestion
              v-else-if="element.type == 'checkbox'"
              :value="element.value"
              :question="element.question"
              :textfield="element.textfield"
              :checkboxes="element.checkboxes"
              @checkboxClicked="($event) => {
                infoTabs[indexInfoTab].items[indexInfoTabElement].value = $event.value
                updateCustomerForm()
              }"
              @textfieldChanged="($event) => {
                infoTabs[indexInfoTab].items[indexInfoTabElement].textfield = $event
                updateCustomerForm()
              }"
            ></CheckBoxQuestion>  
          <TableQuestion
            v-else-if="element.type == 'table'"
            :items="element.value"
          ></TableQuestion>  
        </div>
      </div>
    </div>
      <table v-if="infoTabs.length > 0" style="width: 60%" class="extentionTable">
        <tr>
          <td style="width: 50%">
            <v-img
              :src="require('@/assets/beautyEyes.png')"
              contain
              gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,0.2)"
              max-width="30vw"
              min-height="21vh"
            > 
            </v-img>
          </td>  
          <td>
            <v-textarea
              label="Ciglia"
              @input="updateCustomerForm"
              v-model="infoTabs[0].textfield[0]"
              color="grey"
              rows="3"
              hide-details="auto"
            ></v-textarea>
            <v-textarea
              label="Sottociglia"
              @input="updateCustomerForm"
              v-model="infoTabs[0].textfield[1]"
              color="grey"
              rows="3"
              hide-details="auto"
            ></v-textarea>  
          </td>
        </tr>
      </table>
  </div>
</template>

<script>
import formsDataSheet from './FormsDataSheetJson.js'
import customerForm from "@/services/customers/customer.form.js";
import OpenQuestion from "./components/OpenQuestion.vue";
import CheckBoxQuestion from "./components/CheckBoxQuestion.vue";
import TableQuestion from "./components/TableQuestion.vue";

export default {
  name: 'BeautyExtentionDataSheet',
  components: {
    OpenQuestion,
    CheckBoxQuestion,
    TableQuestion,
  },
  data: function() {
    return {
      newForm: new formsDataSheet(),
      infoTabs: [],
      loading: false,
    }
  },
  props: {
    completed:{
      type: Boolean,
      default: false,
    },
  },
  mounted: function() {
    if(!customerForm.customer.beautyDataSheet){
      customerForm.updateField("beautyDataSheet", this.newForm.beautyForm);
      this.infoTabs = [...this.newForm.beautyForm.beautyExtention]
    }
    else if(!!customerForm.customer.beautyDataSheet && !!customerForm.customer.beautyDataSheet.beautyExtention){
      this.infoTabs = !!customerForm.customer.beautyDataSheet.beautyExtention.data ? [...customerForm.customer.beautyDataSheet.beautyExtention.data] : [...customerForm.customer.beautyDataSheet.beautyExtention]
    }

    this.updateCustomerForm()
  },
  methods: {
    /**
     * Update customer form Object
     */
    updateCustomerForm() {
      if (!!customerForm.customer.beautyDataSheet && !!customerForm.customer.beautyDataSheet.beautyExtention) {
        customerForm.updateObjectField("beautyDataSheet","beautyExtention", this.infoTabs);
      } else if(!!customerForm.customer.beautyDataSheet) {
        // Creation case
        customerForm.updateObjectField("beautyDataSheet","beautyExtention", this.infoTabs);
      }
      else {
        console.log('Error in updateLocalField');
      }
      this.checkAllCompleted()
    },
    /**
     * Check if all the fields are completed
     */
    checkAllCompleted(){
      let completed = false
      completed = this.infoTabs.some((tab) => {
        let localCompleted = false
        if (!!tab.textfield) {
          if (Array.isArray(tab.textfield))
            localCompleted = tab.textfield.some(el => el !== null && el !== undefined && el !== "")
          else localCompleted = (tab.textField !== null && tab.textField !== undefined && tab.textField !== "")
        }

        if (localCompleted == false) {
          return tab.items.some((item) => {
            if (!!item.checkboxes) {
              return (item.value !== null && item.value !== undefined && item.value !== "") ||
                (item.textfield !== null && item.textfield !== undefined && item.textfield !== "")
            }
            if (Array.isArray(item.value))
              return item.value.some(el => (el.value !== null && el.value !== undefined && el.value !== ""))
            return item.value !== null && item.value !== undefined && item.value !== ""
          })
        }

        return localCompleted
      })
      this.$emit('update:completed', completed)
    },
  },
  watch: {
  }
}
</script>

<style scoped>
table, th, td {
  border: 1px solid black;
}
</style>
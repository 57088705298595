<template>
<div>
  <div v-if="historyInfoTabs.length == 0">
    <div class="d-lg-flex" style="overflow-x: scroll;">
      <div 
        class="d-flex align-left flex-column pr-2"
        :class="{
          'mb-2': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md,
        }"
        style="min-width: 200px;"
      >
        <v-btn @click="handleShowHistoryDialog"> storico </v-btn>
        <div class="pt-2">
          <DatePicker
            v-model="dateToDisplay"
            label="Data Modifica"
            dense
            solo-inverted
            filled
            readonly
            disabled
          ></DatePicker>
        </div>
        <div class="pt-2">
          <OperatorsAutocomplete
            label="Operatore"
            return-object
            v-model="operatorToDisplay"
            :multiple="false"
            disabled
            dense
            solo-inverted
          ></OperatorsAutocomplete>
        </div>
      </div>
      <TableBarberColoring
        v-for="(infoTab, i) in infoTabs.slice(0, 3)"
        :key="infoTab.key"
        :color="colorsMainTables[i]"
        :title="titleMainTables[i]"
        :infoDown="infoTab.infoDown"
        :oxValues="infoTab.oxValues"
        :items="infoTab.items"
        :colorTypeId="infoTab.colorTypeId"
        @infoDown="updateVal($event, i, 'infoDown')"
        @items="updateVal($event, i, 'items')"
        @oxValues="updateVal($event, i, 'oxValues')"
        @colorTypeId="updateVal($event, i, 'colorTypeId')"
        class="pr-2"
        :no-title="true"
        :style="{
          'min-width': $vuetify.breakpoint.xl || $vuetify.breakpoint.lg ? '800px' : null
        }"
      ></TableBarberColoring>
      <TableBarberLightening
        v-for="(infoTab, i) in infoTabs.slice(3, 5)"
        :key="infoTab.key"
        color="#bccad6"
        :title="titleSecondaryTables[i]"
        :infoUp="infoTab.infoUp"
        :items="infoTab.items"
        :imagePath="images[i]"
        @infoUp="updateVal($event, i + 3, 'infoUp')"
        @items="updateVal($event, i + 3, 'items')"
      ></TableBarberLightening>
    </div>
  </div>
  <div v-else-if="historyInfoTabs.length > 0">
    <div class="d-lg-flex" style="overflow-x: scroll;">
      <div class="d-flex align-left flex-column pr-2" style="min-width: 260px;">
        <v-btn
          class="py-2 mb-1"
          @click="handleShowHistoryDialog"
          color="#b5e7a0"
        > storico </v-btn>
        <v-btn
          class="py-2 mb-1"
          @click="historyTabsDisabled = false"
          color="#ffef96"
        > modifica storico </v-btn>
        <v-btn
          class="py-2"
          @click="infoTabs = [...presentInfoTabs]; historyInfoTabs = []; dateToDisplay = new Date(infoTabs[0].date); operatorToDisplay = infoTabs[0].operator; operatorWhoModified = undefined; historyTabsDisabled = true"
          color="#eca1a6"
        > ritorna ad ultima modifica </v-btn>
        <div class="pt-2">
          <DatePicker
            v-model="dateToDisplay"
            label="Data Modifica"
            dense
            filled
            readonly
            disabled
            solo-inverted
          ></DatePicker>
        </div>
        <div class="pt-2">
          <OperatorsAutocomplete
            label="Operatore"
            v-model="operatorToDisplay"
            disabled
            return-object
            dense
            solo-inverted
          ></OperatorsAutocomplete>
        </div>
      </div>
      <TableBarberColoring
        v-for="(infoTab, i) in historyInfoTabs.slice(0, 3)"
        :key="infoTab.key"
        :color="colorsMainTables[i]"
        :title="titleMainTables[i]"
        :infoDown="infoTab.infoDown"
        :oxValues="infoTab.oxValues"
        :items="infoTab.items"
        :colorTypeId="infoTab.colorTypeId"
        :disabled="historyTabsDisabled"
        @infoDown="updateVal($event, i, 'infoDown', true)"
        @items="updateVal($event, i, 'items', true)"
        @oxValues="updateVal($event, i, 'oxValues', true)"
        @colorTypeId="updateVal($event, i, 'colorTypeId', true)"
        class="pr-2"
        style="min-width: 800px;"
        :no-title="true"
      ></TableBarberColoring>
      <TableBarberLightening
        v-for="(infoTab, i) in historyInfoTabs.slice(3, 5)"
        :key="infoTab.key"
        color="#bccad6"
        :title="titleSecondaryTables[i]"
        :infoUp="infoTab.infoUp"
        :items="infoTab.items"
        :imagePath="images[i]"
        :disabled="historyTabsDisabled"
        @infoUp="updateVal($event, i + 3, 'infoUp')"
        @items="updateVal($event, i + 3, 'items')"
      ></TableBarberLightening>
    </div>
  </div>
  <StandardDialog
    title="Lista Storico"
    v-model="showHistoryDialog"
    :dialog-height="null"
  >
    <TypeDataTable
      v-model="historyBarberSetting"
      loading-text="Caricamento Storico"
      :headers="historyBarberHeaders"
      :items="historyBarberSettingsItems"
      :page.sync="page"
      :row-per-page.sync="rowPerPage"
      :total-pages.sync="totalPages"
      :show-actions="false"
      :show-select="false"
      single-select
      @input="historyBarberSettingSelected"
    >
      <template v-slot:custom-customOperator="{item}">
        <v-chip v-if="item.barberColoring.data && item.barberColoring.data[0] && item.barberColoring.data[0].operator">
          {{item.barberColoring.data[0].operator.fullname}}
        </v-chip>
        <v-chip v-else>
          {{'Operatore non presente'}}
        </v-chip>
      </template>  
      <template v-slot:custom-corona="{item}">
        <v-chip v-if="item.barberColoring.data && item.barberColoring.data[0] && item.barberColoring.data[0].items" color="#c5d5c5">
          Tono:
          {{item.barberColoring.data[0].items.map(el => {if(el.checked) return el}).filter(Boolean).map(el =>  el.value + ' Grammatura: ' + el.grammatura + ' Note: ' + el.colorType).join(', ')}}
           - Ox:
          {{item.barberColoring.data[0].oxValues.join(', ')}}
          {{item.barberColoring.data[0].infoDown}}
        </v-chip>
      </template>  
      <template v-slot:custom-coronaTipoColorazione="{item}">
        <ColorLinesAutocomplete
          v-if="!!item.barberColoring.data[0].colorTypeId"
          :multiple="false"
          :clearable="false"
          v-model="item.barberColoring.data[0].colorTypeId"
          :filters="{archived: true}"
          disabled
          class="py-2"
        ></ColorLinesAutocomplete>
        <p v-else>Non Presente</p>
      </template> 
      <template v-slot:custom-colorazione="{item}">
        <v-chip v-if="item.barberColoring.data && item.barberColoring.data[1] && item.barberColoring.data[1].items" color="#9fa9a3">
          Tono:
          {{item.barberColoring.data[1].items.map(el => {if(el.checked) return el}).filter(Boolean).map(el =>  el.value + ' Grammatura: ' + el.grammatura + ' Note: ' + el.colorType).join(', ')}}
           - Ox:
          {{item.barberColoring.data[1].oxValues.join(', ')}}
          {{item.barberColoring.data[1].infoDown}}
        </v-chip>
      </template>  
      <template v-slot:custom-colorazioneTipoColorazione="{item}">
        <ColorLinesAutocomplete
          v-if="!!item.barberColoring.data[1].colorTypeId"
          :multiple="false"
          :clearable="false"
          v-model="item.barberColoring.data[1].colorTypeId"
          :filters="{archived: true}"
          disabled
          class="py-2"
        ></ColorLinesAutocomplete>
        <p v-else>Non Presente</p>
      </template> 
      <template v-slot:custom-tonalizzazione="{item}">
        <v-chip v-if="item.barberColoring.data && item.barberColoring.data[2] && item.barberColoring.data[2].items" color="#e3e0cc">
          Tono:
          {{item.barberColoring.data[2].items.map(el => {if(el.checked) return el}).filter(Boolean).map(el =>  el.value + ' Grammatura: ' + el.grammatura + ' Note: ' + el.colorType).join(', ')}}
          - Ox:
          {{item.barberColoring.data[2].oxValues.join(', ')}}
          {{item.barberColoring.data[2].infoDown}}
        </v-chip>
      </template> 
      <template v-slot:custom-tonalizzazioneTipoColorazione="{item}">
        <ColorLinesAutocomplete
          v-if="!!item.barberColoring.data[2].colorTypeId"
          :multiple="false"
          :clearable="false"
          v-model="item.barberColoring.data[2].colorTypeId"
          :filters="{archived: true}"
          disabled
          class="py-2"
        ></ColorLinesAutocomplete>
        <p v-else>Non Presente</p>
      </template>  
    </TypeDataTable>
    <template v-slot:footer-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="red"
        text
        @click="handleCloseHistoryDialog"
      >
        Chiudi
      </v-btn>  
    </template>
  </StandardDialog>
  <StandardDialog
    title="Modifica da parte di operatore"
    v-model="showOperatorDialog"
    :dialog-height="null"
    dialog-width="700px"
    persistent
  >
    <OperatorsAutocomplete
      class="ml-1 pa-1"
      label="Chi Sei?"
      v-model="operatorWhoModified"
      return-object
      :clearable="false"
      :multiple="false"
    ></OperatorsAutocomplete>
      <template v-slot:footer-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!!operatorWhoModified"
          color="red"
          text
          @click="showOperatorDialog = false"
        >
          Chiudi
        </v-btn>  
      </template>
  </StandardDialog>

  <SimplePopUp
    v-model="alertColoring"
    :width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '80vw' :
          $vuetify.breakpoint.xl ? '30vw' : '35vw'"
    alert-text="Selezionare un tipo di colorazione per procedere alla modifica della scheda colore"
    :dismissible="true"
  >
  </SimplePopUp>
</div>
</template>

<script>
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import TableBarberColoring from "./components/TableBarberColoring.vue";
import TableBarberLightening from "./components/TableBarberLightening.vue";
import capello from "@/assets/capello.png";
import schiariture from "@/assets/schiariture.png";
import customerForm from "@/services/customers/customer.form.js";
import DatePicker from "@/components/common/DatePickerCommon.vue";
import StandardDialog from '@/components/common/StandardDialog.vue'
import customerService from '@/services/customers/customers.service.js'
import OperatorsAutocomplete from "@/components/common/OperatorsAutocomplete.vue";
import formsDataSheet from './FormsDataSheetJson.js'
import ColorLinesAutocomplete from "@/components/common/ColorLinesAutocomplete.vue";
import SimplePopUp from '@/components/common/SimplePopUp.vue'
import colorLineService from "@/services/colorLines/colorLines.service.js";

export default {
  name: "barberColoring",
  components: {
    TypeDataTable,
    TableBarberColoring,
    TableBarberLightening,
    DatePicker,
    StandardDialog,
    customerService,
    OperatorsAutocomplete,
    ColorLinesAutocomplete,
    SimplePopUp
  },
  data: function () {
    return {
      newForm: new formsDataSheet(),
      infoTabs: [],
      colorsMainTables: ["#c5d5c5", "#9fa9a3", "#e3e0cc"],
      titleMainTables: [
        "COLORE CORONA",
        "COLORAZIONE",
        "TONALIZZAZIONE COLORE",
      ],
      colorSecondaryTables: "#bccad6",
      titleSecondaryTables: ["SCHIARITURE", "TONALIZZAZIONE SCHIARITURE"],
      images: [schiariture, capello],
      dateToDisplay: undefined,
      operatorToDisplay: undefined,
      historyInfoTabs: [],
      showHistoryDialog: false,
      page: 1,
      rowPerPage: 20,
      totalPages: 100,
      historyBarberSettingsItems: [],
      historyBarberSetting: undefined,
      historyBarberHeaders: [
        { text: "Data Modifica", value: "createdAt", type: "datetime", width: "10vw"},
        { text: "Operatore", value: "customOperator", type: "custom", width: "10vw"  },
        { text: "Colore Corona", value: "corona", type: "custom", width: "10vw"  },
        { text: "Tipo Colorazione Corona", value: "coronaTipoColorazione", type: "custom", width: "10vw"  },
        { text: "Colorazione", value: "colorazione", type: "custom",width: "10vw"  },
        { text: "Tipo Colorazione Colorazione", value: "colorazioneTipoColorazione", type: "custom", width: "10vw" },
        { text: "Tonalizzazione Colore", value: "tonalizzazione", type: "custom", width: "10vw"  },
        { text: "Tipo Colorazione Tonalizzazione Colore", value: "tonalizzazioneTipoColorazione", type: "custom", width: "10vw"  },
      ],
      presentInfoTabs: [],
      operatorWhoModified: undefined,
      showOperatorDialog: false,
      historyTabsDisabled: true,
      customerId: undefined,
      fetchHistoryTabPromise: undefined,
      alertColoring: true
    };
  },
  props: {
    completed: {
      type: Boolean,
      default: false,
    },
  },
  mounted: function () {
    this.infoTabs = [...this.newForm.barberForm.barberColoring]

    let self = this
    customerForm.on('update', function (data) {
      //self.test()
      self.fetchHistoryTabs()
    })

    /*if (!customerForm.customer.barberDataSheet) {
      customerForm.updateField("barberDataSheet", this.newForm.barberForm);
    } else if (
      !!customerForm.customer.barberDataSheet &&
      !!customerForm.customer.barberDataSheet.barberColoring
    ) {
      let infotabsFromDB = !!customerForm.customer.barberDataSheet.barberColoring.data ? [...customerForm.customer.barberDataSheet.barberColoring.data] : [...customerForm.customer.barberDataSheet.barberColoring]
      if(!!infotabsFromDB[0].version && this.infoTabs[0].version > infotabsFromDB[0].version) {
        for (let i = 0; i < infotabsFromDB.length; i++) {
          if(i < 3){
            this.infoTabs[i].operator = infotabsFromDB[i].operator
            this.infoTabs[i].colorTypeId = infotabsFromDB[i].colorTypeId
            this.infoTabs[i].infoDown = infotabsFromDB[i].infoDown
            this.infoTabs[i].value = infotabsFromDB[i].value
            this.infoTabs[i].oxValues = infotabsFromDB[i].oxValues 
            if(this.infoTabs[i].items.length >= infotabsFromDB[i].items.length) {
              let itemsToAdd = this.infoTabs[i].items.slice(infotabsFromDB[i].items.length)
              this.infoTabs[i].items = [...infotabsFromDB[i].items ,...itemsToAdd]
            }
          }
        } 
      }
      else {
        this.infoTabs = [...infotabsFromDB]
      } 
    }
    this.updateCustomerForm();*/
    if (!!this.infoTabs[0]["date"])
      this.dateToDisplay = new Date(this.infoTabs[0]["date"]);
      
    if (!!this.infoTabs[0]["operator"])
      this.operatorToDisplay = this.infoTabs[0]["operator"];

    if(!!customerForm.customer.id)
      this.fetchHistoryTabs()
  },
  methods: {
    test() {
      if (
        !!customerForm.customer.barberDataSheet &&
        !!customerForm.customer.barberDataSheet.barberColoring
      ) {
        let infotabsFromDB = !!customerForm.customer.barberDataSheet.barberColoring.data ? [...customerForm.customer.barberDataSheet.barberColoring.data] : [...customerForm.customer.barberDataSheet.barberColoring]
        if(!!infotabsFromDB[0].version && this.infoTabs[0].version > infotabsFromDB[0].version) {
          for (let i = 0; i < infotabsFromDB.length; i++) {
            if(i < 3){
              this.infoTabs[i].operator = infotabsFromDB[i].operator
              this.infoTabs[i].colorTypeId = infotabsFromDB[i].colorTypeId
              this.infoTabs[i].infoDown = infotabsFromDB[i].infoDown
              this.infoTabs[i].value = infotabsFromDB[i].value
              this.infoTabs[i].oxValues = infotabsFromDB[i].oxValues 
              if(this.infoTabs[i].items.length >= infotabsFromDB[i].items.length) {
                let itemsToAdd = this.infoTabs[i].items.slice(infotabsFromDB[i].items.length)
                this.infoTabs[i].items = [...infotabsFromDB[i].items ,...itemsToAdd]
              }
            }
          } 
        }
        else {
          this.infoTabs = [...infotabsFromDB]
        } 
      }
      if (!!this.infoTabs[0]["date"])
        this.dateToDisplay = new Date(this.infoTabs[0]["date"]);
        
      if (!!this.infoTabs[0]["operator"])
        this.operatorToDisplay = this.infoTabs[0]["operator"];

      if(!!customerForm.customer.id)
        this.fetchHistoryTabs()
    },
    /**
     * Update customer form Object
     */
    updateCustomerForm(fromHistory = false) {
      if (
        !!customerForm.customer.barberDataSheet &&
        !!customerForm.customer.barberDataSheet.barberColoring
      ) {
        customerForm.updateObjectField(
          "barberDataSheet",
          "barberColoring",
          !fromHistory ? this.infoTabs : this.historyInfoTabs
        );
      } else if (!!customerForm.customer.barberDataSheet) {
        // Creation case
        customerForm.updateObjectField(
          "barberDataSheet",
          "barberColoring",
          !fromHistory ? this.infoTabs : this.historyInfoTabs
        );
      } else {
        console.log("Error in updateLocalField");
      }
      this.checkCompleted()
    },
    updateVal(event, keyInfoTab, keyItem, fromHistory = false) {
      if(!fromHistory){
        if(!this.operatorWhoModified){
          this.showOperatorDialog = true;
        }
        this.infoTabs[keyInfoTab][keyItem] = event;
        this.infoTabs[0]["date"] = new Date();
        this.dateToDisplay = this.infoTabs[0]["date"];
      }
      else {       
        if(!this.operatorWhoModified){
          this.showOperatorDialog = true;
        }
        this.historyInfoTabs[keyInfoTab][keyItem] = event;
        this.historyInfoTabs[0]["date"] = new Date();
        this.dateToDisplay = this.historyInfoTabs[0]["date"];
      }
      customerForm.updateObjectField("barberDataSheet", "barberColoringUpdated", true);
      this.updateCustomerForm(fromHistory);
    },
    handleShowHistoryDialog() {
      this.showHistoryDialog = true
      colorLineService.setAlreadyLoaded(false)
    },
    handleCloseHistoryDialog() {
      this.showHistoryDialog = false
      colorLineService.setAlreadyLoaded(false)
    },
    fetchHistoryTabs() {
      if(!!this.fetchHistoryTabPromise) return this.fetchHistoryTabPromise

      this.fetchHistoryTabPromise = new Promise(async (resolve, reject) => {
        try {
          if(!customerForm.customer.id) throw new Error('Customer id not present')
          if (customerForm.customer.id === this.customerId) return
          this.customerId = customerForm.customer.id

          let result = await customerService.listBarberColoringSettings(customerForm.customer.id, this.page, this.rowPerPage)
          this.historyBarberSettingsItems = [...result]

          let lastItem = this.historyBarberSettingsItems[0]
          if(result.length > 0 && !!lastItem && !!lastItem.barberColoring.data && 
            !!lastItem.barberColoring.data[0].date)
            this.$emit('update:completed', true)
          this.historyBarberSettingsItems.shift()

          if(!!lastItem) {
            let infotabsFromDB = !!lastItem.barberColoring.data ? [...lastItem.barberColoring.data] : [...lastItem.barberColoring]
            
            if (!!infotabsFromDB[0]["date"])
              this.dateToDisplay = new Date(infotabsFromDB[0]["date"]);
      
             if (!!infotabsFromDB[0]["operator"])
              this.operatorToDisplay = infotabsFromDB[0]["operator"];

            if(!!infotabsFromDB[0].version && this.infoTabs[0].version > infotabsFromDB[0].version) {
              for (let i = 0; i < infotabsFromDB.length; i++) {
                if(i < 3) {
                  this.infoTabs[i].operator = infotabsFromDB[i].operator
                  this.infoTabs[i].colorTypeId = infotabsFromDB[i].colorTypeId
                  this.infoTabs[i].infoDown = infotabsFromDB[i].infoDown
                  this.infoTabs[i].value = infotabsFromDB[i].value
                  this.infoTabs[i].oxValues = infotabsFromDB[i].oxValues
                  if(this.infoTabs[i].items.length >= infotabsFromDB[i].items.length) {
                    let itemsToAdd = this.infoTabs[i].items.slice(infotabsFromDB[i].items.length)
                    this.infoTabs[i].items = [...infotabsFromDB[i].items, ...itemsToAdd]
                  }
                }
              }
            }
            else {
              this.infoTabs = [...infotabsFromDB]
            }
          }
        } catch (error) {
          console.log(error)
        }

        resolve(undefined)
      })

      return this.fetchHistoryTabPromise
    },
    async historyBarberSettingSelected(item){
      try {
        if(Array.isArray(item) && item.length == 0){
          this.showHistoryDialog = false
          colorLineService.setAlreadyLoaded(false)
          return
        }
        if(this.infoTabs.length > 0) 
          this.presentInfoTabs = [...this.infoTabs]
        this.infoTabs = []
        this.historyInfoTabs = [...item[0].barberColoring.data]
        if (!!this.historyInfoTabs[0]["date"])
          this.dateToDisplay = new Date(this.historyInfoTabs[0]["date"]);
        if (!!this.historyInfoTabs[0]["operator"])
          this.operatorToDisplay = this.historyInfoTabs[0]["operator"];           
        this.showHistoryDialog = false
        colorLineService.setAlreadyLoaded(false)
        this.historyBarberSetting = []
        this.historyTabsDisabled = true
      }
      catch(err){
        console.log(err)
      }
    },

    checkCompleted() {
      let completed = false
      completed = this.infoTabs.some((tab) => {
        return (
          tab.items.some((item) => {
            if (!!item.description) {
              return (item.description !== null && item.description !== undefined && item.description !== "")
            }

            if (!!item.checked) {
              return (item.checked !== null && item.checked !== undefined && item.checked !== "") ||
                (item.colorType !== null && item.colorType !== undefined && item.colorType !== "") ||
                (item.grammatura !== null && item.grammatura !== undefined && item.grammatura !== "")
            }
          }) ||
          (tab.oxValues !== null &&
          tab.oxValues !== undefined &&
          tab.oxValues.some((oxValue) => {
            return oxValue !== null && oxValue !== undefined && oxValue !== ""
          }) ||
          tab.infoDown !== null && tab.infoDown !== undefined && tab.infoDown !== "" ||
          tab.infoUp !== null && tab.infoUp !== undefined && tab.infoUp !== "" ||
          tab.colorTypeId !== null && tab.colorTypeId !== undefined && tab.colorTypeId !== ""
        ))
      })
      this.$emit('update:completed', completed)
    }
  },
  watch: {
    page() {
      this.fetchHistoryTabs();
    },
    rowPerPage() {
      this.fetchHistoryTabs();
    },
    totalPages() {
      this.fetchHistoryTabs();
    },
    showHistoryDialog() {
      if (this.showHistoryDialog) {
        this.fetchHistoryTabs();
      }
    },
    operatorWhoModified(){
      if(!!this.historyTabsDisabled && !!this.operatorWhoModified){
        this.infoTabs[0]["operator"] = this.operatorWhoModified
        this.operatorToDisplay = this.infoTabs[0]["operator"];
      }
      else if(!this.historyTabsDisabled && !!this.operatorWhoModified){
        this.historyInfoTabs[0]["operator"] = this.operatorWhoModified
        this.operatorToDisplay = this.historyInfoTabs[0]["operator"];
      }
    }
  },
};
</script>


<style>
</style> 
<template>
  <div>
    <v-form ref="invoiceFormTab">
      <v-row class="mt-2 ml-3">
        <v-col
          cols="6"
          sm="6"
          md="4"
          lg="4"
          xl="4"
        >
          <v-text-field
            v-model="lastname"
            label="Regione Sociale O Cognome"
            prepend-icon="mdi-account-arrow-right"
            hide-details="auto"
            filled
            dense
            :rules="[presenceRule]"
            @input="updateLocalField('lastname')"
            rounded
          ></v-text-field>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="4"
          lg="4"
          xl="4"
        >
          <v-text-field
            v-model="firstname"
            label="Nome"
            prepend-icon="mdi-account"
            hide-details="auto"
            :rules="[presenceRule]"
            filled
            dense
            @input="updateLocalField('firstname')"
            rounded
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-text-field
            v-model="address"
            label="Indirizzo"
            prepend-icon="mdi-map-marker-outline"
            hide-details="auto"
            :rules="[presenceRule]"
            filled
            dense
            @input="updateLocalField('address')"
            rounded
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-2 ml-3">
        <v-col
          cols="6"
          sm="6"
          md="4"
          lg="4"
          xl="4"
        >
          <v-text-field
            v-model="cap"
            label="CAP"
            prepend-icon="mdi-code-tags"
            hide-details="auto"
            filled
            dense
            :rules="[presenceRule]"
            @input="updateLocalField('cap')"
            rounded
          ></v-text-field>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="4"
          lg="4"
          xl="4"
        >
          <v-text-field
            v-model="city"
            label="Città"
            prepend-icon="mdi-city-variant-outline"
            hide-details="auto"
            :rules="[presenceRule]"
            filled
            dense
            @input="updateLocalField('city')"
            rounded
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-text-field
            v-model="province"
            label="Provincia"
            prepend-icon="mdi-map-search-outline"
            hide-details="auto"
            :rules="[presenceRule]"
            maxlength="2"
            filled
            dense
            @input="updateLocalField('province')"
            rounded
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-2 ml-3">
        <v-col
          cols="6"
          sm="6"
          md="4"
          lg="4"
          xl="4"
        >
          <v-text-field
            v-model="mail"
            label="Email PEC"
            prepend-icon="mdi-email"
            hide-details="auto"
            filled
            dense
            @input="updateLocalField('mail')"
            rounded
          ></v-text-field>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="4"
          lg="4"
          xl="4"
        >
          <v-text-field
            v-model="destinataryCode"
            label="Codice Destinatario"
            prepend-icon="mdi-qrcode"
            hide-details="auto"
            filled
            dense
            @input="updateLocalField('destinataryCode')"
            rounded
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-text-field
            v-model="vatNumber"
            label="Partita IVA"
            prepend-icon="mdi-file-code-outline"
            hide-details="auto"
            :error="!vatNumber && !fiscalCode"
            maxlength="11"
            filled
            dense
            @input="updateLocalField('vatNumber')"
            rounded
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="false" class="mt-2 ml-3">
        <v-col
          cols="6"
          sm="6"
          md="4"
          lg="4"
          xl="4"
        >
           <v-select
            v-model="paymentTermId"
            :items="paymentTermsItems"
            item-value="id"
            item-text="description"
            return-object
            label="Modalità Di Pagamento"
            filled
            deletable-chips
            dense
            chips
            small-chips
            hide-details="auto"
            prepend-icon="mdi-contactless-payment"
            @input="updateLocalField('paymentTermId')"
            rounded
          ></v-select>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="4"
          lg="4"
          xl="4"
        >
          <v-select
            v-model="paymentConditionId"
            :items="paymentConditionItems"
            item-value="id"
            item-text="description"
            return-object
            label="Condizione Di Pagamento"
            filled
            deletable-chips
            dense
            chips
            small-chips
            hide-details="auto"
            prepend-icon="mdi-contactless-payment"
            @input="updateLocalField('paymentConditionId')"
            rounded
          ></v-select>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="4"
          lg="4"
          xl="4"
        >
           <v-select
            v-model="paymentTypeNatureExemptionId"
            :items="paymentTypeNatureExemptionItems"
            item-value="id"
            item-text="description"
            return-object
            label="Tipo Natura Esenzione IVA"
            filled
            deletable-chips
            dense
            chips
            small-chips
            hide-details="auto"
            prepend-icon="mdi-contactless-payment"
            @input="updateLocalField('paymentTypeNatureExemptionId')"
            rounded
          ></v-select>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="4"
          lg="4"
          xl="4"
        >
          <v-autocomplete
            v-model="docPrint"
            :items="docPrintItems"
            :clearable="true"
            dense
            filled
            prepend-icon="mdi-cloud-print-outline"
            hide-details="auto"
            label="Stampa Nel Documento Commerciale"
            @change="updateLocalField('docPrint')"
            rounded
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="mt-1 ml-3">
        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="6"
          xl="6"
        >
          <v-text-field
            v-model="lotteryCode"
            label="Codice Lotteria"
            prepend-icon="mdi-clover"
            hide-details="auto"
            filled
            dense
            @input="updateLocalField('lotteryCode')"
            rounded
          ></v-text-field>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="6"
          xl="6"
        >
          <v-text-field
            v-model="fiscalCode"
            label="Codice Fiscale"
            prepend-icon="mdi-card-account-details"
            hide-details="auto"
            filled
            dense
            :error="!vatNumber && !fiscalCode"
            @input="updateLocalField('fiscalCode')"
            rounded
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import customerForm from '@/services/customers/customer.form.js'
import debounce from "@/mixins/common/debounce.js"
import dataInvoiceService from "@/services/invoices/dataInvoices.service.js";
import paymentTermService from "@/services/paymentTerms/paymentTerms.service.js";
import paymentConditionService from "@/services/paymentTerms/paymentConditions.service.js";
import paymentTypeNatureExemptionService from "@/services/paymentTerms/paymentTypeNatureExemptions.service.js";

export default {
  name: "InvoiceFormTab",
  components: {},
  mixins: [debounce],
  data: function () {
    return {
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
      VATOrFiscal: (v) => {
        return !!this.vatNumber || !!this.fiscalCode || 'IVA o Codice Fiscale obbligatorio'
      },
      mail: undefined,
      vatNumber: undefined,
      destinataryCode: undefined,
      lastname: undefined,
      firstname: undefined,
      address: undefined,
      cap: undefined,
      city: undefined,
      province: undefined,
      paymentTypeNatureExemptionId: undefined,
      paymentTypeNatureExemptionItems: [],
      paymentConditionId: undefined,
      paymentConditionItems: [],
      paymentTermId: undefined,
      paymentTermsItems: [],
      docPrint: undefined,
      docPrintItems: [],
      lotteryCode: undefined,
      fiscalCode: undefined,
      infoToSend: {},
    };
  },
  mounted: function () {
    this.fetchPaymentTerms()
    this.updateLocalData();
    customerForm.on("update", this.updateLocalData);
    this.$refs.invoiceFormTab.validate()
    this.$once("hook:beforeDestroy", function () {
      customerForm.off("update", this.updateLocalData);
    });
  },
  methods: {
    fetchPaymentTerms(){
      new Promise(async (resolve, reject) =>{

        let resultPaymentTermsItems = await paymentTermService.list()
        this.paymentTermsItems =  [...resultPaymentTermsItems.rows]

        let resultPaymentConditionItems = await paymentConditionService.list()
        this.paymentConditionItems =  [...resultPaymentConditionItems.rows]


        let resultPaymentTypeNatureExemptionItems = await paymentTypeNatureExemptionService.list()
        this.paymentTypeNatureExemptionItems =  [...resultPaymentTypeNatureExemptionItems.rows]     
        
        this.docPrintItems = dataInvoiceService.listDocPrint();
        resolve();
      })
    },
    updateLocalData() {
      if (!!customerForm.customer.dataInvoice) {
        this.infoToSend.id = customerForm.customer.dataInvoice.id
        const localAttributes = [
          "mail",
          "vatNumber",
          "destinataryCode",
          "lastname",
          "firstname",
          "address",
          "cap",
          "city",
          "province",
          "paymentConditionId",
          "paymentTermId",
          "paymentTypeNatureExemptionId",
          "docPrint",
          "lotteryCode",
          "fiscalCode",
        ]
        localAttributes.forEach((nameAttribute) => {
          this[nameAttribute] =
            customerForm.customer.dataInvoice[nameAttribute];
          this.infoToSend[nameAttribute] =
            customerForm.customer.dataInvoice[nameAttribute];
        })
      }
    },
    updateLocalField(field) {
      if (!!customerForm.customer.dataInvoice) {
        //Update Case
        if(typeof this[field] == 'object' ) {
          if(!!this[field])
          this.infoToSend[field] = this[field].id 
          else this.infoToSend[field] = null 
        }else this.infoToSend[field] = this[field];
        customerForm.updateField("dataInvoice", this.infoToSend);
      } else {
        // Creation case
        customerForm.updateField("dataInvoice", {});
        if(typeof this[field] == 'object') {
           if(!!this[field])
          this.infoToSend[field] = this[field].id 
          else this.infoToSend[field] = null 
        }else this.infoToSend[field] = this[field];
        customerForm.updateField("dataInvoice", this.infoToSend);
      }
    },
  },
};
</script>

<style scoped>
</style>
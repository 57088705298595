<template>
  <div class="d-flex align-center flex-column pt-5">
    <div v-if="!!infoTabs[0]">
      <v-row v-for="key in Object.keys(infoTabs[0].items[0].values)" :key="key">
        <v-col cols="12" class="d-flex justify-center">
          <h2>
            {{ getTableName(key) }}
          </h2>
        </v-col>
        <v-col>
          <table style="min-width:80vw">
            <tr :style="{backgroundColor: getColorHeader(key)}">
              <th>
                N° Seduta
              </th>
              <th>
                Operatore
              </th>
              <th>
                Trattamento Previsto
              </th>
              <th>
                Note
              </th>
              <th>
                Eseguito
              </th>
            </tr>
            <tr v-for="(item, idx) in infoTabs[0].items[0].values[key]" :key="idx">
              <td>
                Sessione N° {{ idx + 1 }} 
                <v-btn icon @click="deleteRow(item, key)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
              <td>
                <OperatorsAutocomplete
                  v-model="item.operator"
                  :multiple="false"
                  :return-object="false"
                  prepend-icon
                  label="Operatore"
                  @input="updateCustomerForm"
                ></OperatorsAutocomplete> 
              </td>
              <td>
                <v-text-field
                  v-model="item.service"
                  @input="updateCustomerForm"
                >
                </v-text-field>
              </td>
              <td>
                <v-textarea
                  rows="2"
                  v-model="item.notes"
                  @input="updateCustomerForm"
                >
                </v-textarea>
              </td>
              <td class="d-flex justify-center">
                <v-checkbox v-model="item.done" @change="updateCustomerForm"></v-checkbox>
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import formsDataSheet from './FormsDataSheetJson.js'
import customerForm from "@/services/customers/customer.form.js";
import OperatorsAutocomplete from "@/components/common/OperatorsAutocomplete.vue";

export default {
  name: 'BeautyExtentionDataSheet',
  components: {
    OperatorsAutocomplete,
  },
  data: function() {
    return {
      newForm: new formsDataSheet(),
      infoTabs: [],
      loading: false,
    }
  },
  props: {
    completed:{
      type: Boolean,
      default: false,
    },
  },
  mounted: function() {
    if(!customerForm.customer.beautyDataSheet || !customerForm.customer.beautyDataSheet.beautyWorks){
      customerForm.updateField("beautyDataSheet", this.newForm.beautyForm);
      this.infoTabs = [...this.newForm.beautyForm.beautyWorks]
    }
    else if(!!customerForm.customer.beautyDataSheet && !!customerForm.customer.beautyDataSheet.beautyWorks){
      this.infoTabs = !!customerForm.customer.beautyDataSheet.beautyWorks.data ? [...customerForm.customer.beautyDataSheet.beautyWorks.data] : [...customerForm.customer.beautyDataSheet.beautyWorks]
    }

    this.checkAllCompleted()
  },
  methods: {
    /**
     * Update customer form Object
     */
    updateCustomerForm() {
      if (!!customerForm.customer.beautyDataSheet && !!customerForm.customer.beautyDataSheet.beautyWorks) {
        customerForm.updateObjectField("beautyDataSheet","beautyWorks", this.infoTabs);
      } else if(!!customerForm.customer.beautyDataSheet) {
        // Creation case
        customerForm.updateObjectField("beautyDataSheet","beautyWorks", this.infoTabs);
      }
      else {
        console.log('Error in updateLocalField');
      }
      this.checkAllCompleted()
    },
    /**
     * Check if all the fields are completed
     */
    checkAllCompleted(){
      let completed = false

      for(let key of Object.keys(this.infoTabs[0].items[0].values)){
        completed = completed || this.infoTabs[0].items[0].values[key].some(item => Object.values(item).some(value => !!value))
      }

      this.$emit('update:completed', completed)
    },
    deleteRow(item, key){
      this.infoTabs[0].items[0].values[key] = this.infoTabs[0].items[0].values[key].filter(el => el !== item)
      this.infoTabs[0].items[0].values[key].push({operator: undefined, service: "", notes: "", done: false})
      this.updateCustomerForm()
    },
    getTableName(key){
      switch(key){
        case "face":
          return "Viso"
        case "body":
          return "Corpo"
        case "general":
          return "Generale"
      }
    },
    getColorHeader(key){
      switch(key){
        case "face":
          return "pink"
        case "body":
          return "greenyellow"
        case "general":
          return "aqua"
      }
    }
  },
  watch: {
  }
}
</script>

<style scoped>
table, th {
  border: 1px solid black;
}
td {
  border: 1px solid black;
  padding-left: 5px;
  padding-right: 5px;
}


</style>
import axios from "@/services/axios"
import UrlKeeper from '@/services/UrlKeeper.js'

class DataInvoiceService{

  get(customerOrSupplier) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/dataInvoices/get', customerOrSupplier).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  
  createOrUpdate(customerOrSupplier) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/dataInvoices/createOrUpdate', customerOrSupplier).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/payments/invoices/list", {
          page: page,
          rowPerPage: rowPerPage,
          filters: filters,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }



  listNoIva(){
    return [
      'N1 Escluse Ex Art.15',
      'N2 Non Soggette',
      'N3 Non Imponibili',
      'N4 Esenti',
      'N5 Regime Del Margine',
    ]
  }

  listPaymentCondition(){
    return [
      'TP01 Pagamento A Rate',
      'TP02 Pagamento Completo',
      'TP03 Anticipo',
    ]
  }

  listPaymentMode(){
    return [
      'MP01 Contanti',
      'MP02 Assegno',
      'MP03 Assegno Circolare',
      'MP04 Contanti Presso Tesoreria',
      'MP05 Bonifico',
      'MP06 Vaglia Cambiario',
      'MP07 Bollettino Bancario',
      'MP08 Carta Di Credito',
      'MP09 RID',
      'MP10 RID Utenze',
      'MP11 RID Veloce',
      'MP12 Riba',
      'MP13 MAV',
      'MP14 Quietanza Erario Stato',
      'MP15 Giroconto Su Conti Di Contabilità',
      'MP16 Domiciliazione Bancaria',
      'MP17 Domiciliazione Postale'
    ]
  }

  listDocPrint(){
    return [
      'Codice Fiscale',
      'Partita Iva',
      'Codice Lotteria',
      'Nulla'
    ]
  }

}

export default new DataInvoiceService();

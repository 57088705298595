class FormsDataSheetJson {
  constructor() {
    this.barberForm = {
      barberInfo: [
        {
          version: 1,
          "key": 0,
          "value": [],
          "headers": [
            { "text": "1", "value": "value", "type": "custom", "width": "10vw" },
            { "text": "COME SONO I CAPELLI DEL CLIENTE ?", "value": "type" }
          ],
          "items": [
            {
              "value": 0,
              "type": "NATURALI"
            },
            {
              "value": 1,
              "type": "COLORATI"
            },
            {
              "value": 2,
              "type": "DECOLORATI/DECAPAGGIO"
            },
            {
              "value": 3,
              "type": "COLORATI CON SCHIARITURE"
            },
            {
              "value": 4,
              "type": "PERMAMENTE/STIRATURA"
            }
          ]
        },
        {
          "key": 1,
          "value": [],
          "headers": [
            { "text": "2", "value": "value", "type": "custom", "width": "10vw" },
            { "text": "STATO DEI CAPELLI ?", "value": "type" }
          ],
          "items": [
            {
              "value": 0,
              "type": "SECCHI"
            },
            {
              "value": 1,
              "type": "NORMALI"
            },
            {
              "value": 2,
              "type": "GRASSI"
            },
            {
              "value": 3,
              "type": "INDEBOLITI"
            }
          ]
        },
        {
          "key": 2,
          "value": null,
          "headers": [
            { "text": "3", "value": "value", "type": "custom", "width": "10vw" },
            { "text": "LUNGHEZZA ?", "value": "type" }
          ],
          "items": [
            {
              "value": 0,
              "type": "CORTI"
            },
            {
              "value": 1,
              "type": "MEDI"
            },
            {
              "value": 2,
              "type": "LUNGHI"
            }
          ]
        },
        {
          "key": 3,
          "value": null,
          "headers": [
            { "text": "4", "value": "value", "type": "custom", "width": "10vw" },
            { "text": "DIAMETRO ?", "value": "type" }
          ],
          "items": [
            {
              "value": 0,
              "type": "FINI/SOTTILI"
            },
            {
              "value": 1,
              "type": "NORMALI"
            },
            {
              "value": 2,
              "type": "GRASSI"
            }
          ]
        },
        {
          "key": 4,
          "value": null,
          "headers": [
            { "text": "5", "value": "value", "type": "custom", "width": "10vw" },
            { "text": "TIPOLOGIA ?", "value": "type" }
          ],
          "items": [
            {
              "value": 0,
              "type": "CRESPI"
            },
            {
              "value": 1,
              "type": "RICCI"
            },
            {
              "value": 2,
              "type": "MOSSI"
            },
            {
              "value": 3,
              "type": "LISCI"
            }
          ]
        },
        {
          "key": 5,
          "value": null,
          "headers": [
            { "text": "6", "value": "value", "type": "custom", "width": "10vw" },
            { "text": "DENSITA' ?", "value": "type" }
          ],
          "items": [
            {
              "value": 0,
              "type": "DIRADATI"
            },
            {
              "value": 1,
              "type": "NORMALI"
            },
            {
              "value": 2,
              "type": "FOLTI"
            },
            {
              "value": 3,
              "type": "TENDENTI ALLA CADUTA/CADUTA STAGIONALE"
            }
          ]
        },
        {
          "key": 6,
          "value": [],
          "headers": [
            { "text": "7", "value": "value", "type": "custom", "width": "10vw" },
            { "text": "STATO DELLA CUTE ?", "value": "type" }
          ],
          "items": [
            {
              "value": 0,
              "type": "NORMALE"
            },
            {
              "value": 1,
              "type": "CON FORFORA"
            },
            {
              "value": 2,
              "type": "GRASSA"
            },
            {
              "value": 3,
              "type": "SENSIBILE/ARROSSATA/IRRITATA"
            }
          ]
        }
      ],
      barberExpectations: [
        {
          version: 1,
          "key": 0,
          "value": null,
          "headers": [
            { "text": "1", "value": "value", "type": "custom", "width": "10vw" },
            { "text": "QUANTO SEI SODDISFATTA/O OGGI DEI TIPI CAPELLI ?", "value": "type" }
          ],
          "items": [
            {
              "value": 0,
              "type": "PER NIENTE"
            },
            {
              "value": 1,
              "type": "POCO"
            },
            {
              "value": 2,
              "type": "SUFFICIENTE"
            },
            {
              "value": 3,
              "type": "ABBASTANZA"
            },
            {
              "value": 4,
              "type": "MOLTO"
            }
          ]
        },
        {
          "key": 1,
          "value": [],
          "headers": [
            { "text": "2", "value": "value", "type": "custom", "width": "10vw" },
            { "text": "COSA DESIDERI OGGI PER I TUOI CAPELLI ? (Puoi selezionare più opzioni)", "value": "type" }
          ],
          "items": [
            {
              "value": 0,
              "type": "BRILLANTEZZA PER IL MIO COLORE"
            },
            {
              "value": 1,
              "type": "SCHIARIRE"
            },
            {
              "value": 2,
              "type": "CAMBIARE TONALITA'"
            },
            {
              "value": 3,
              "type": "UNIFORMARE"
            },
            {
              "value": 4,
              "type": "COPRIRE I CAPELLI BIANCHI"
            },
            {
              "value": 5,
              "type": "CAPELLI LUNGHISSIMI"
            },
            {
              "value": 6,
              "type": "RIPARA I DANNI DA PIASTRA E PHON"
            },
            {
              "value": 7,
              "type": "RINFORZARE"
            },
            {
              "value": 8,
              "type": "PIU' VOLUME"
            },
            {
              "value": 9,
              "type": "PIU' DENSITA'"
            },
            {
              "value": 10,
              "type": "DISCIPLINA"
            },
            {
              "value": 11,
              "type": "NUTRIMENTO/IDRATAZIONE"
            },
            {
              "value": 12,
              "type": "LIBERARMI DA CUTE GRASSA, FORFORA E PRURITO"
            }
          ]
        },
        {
          "key": 2,
          "value": "",
          "headers": [
            { "text": "3", "type": "custom", "width": "10vw" },
            { "text": "COSA TI PIACE / NON TI PIACE DEI TUOI CAPELLI ?", "value": "textBox", "type": "custom" }
          ],
          "items": [
            {
              "value": 0,
              "type": ""
            }
          ]
        },
        {
          "key": 3,
          "value": "",
          "headers": [
            { "text": "4", "type": "custom", "width": "10vw" },
            { "text": "COME TE LI SENTI E COSA VORRESTI VALORIZZARE ?", "value": "textBox", "type": "custom" }
          ],
          "items": [
            {
              "value": 0,
              "type": ""
            }
          ]
        }
      ],
      barberHabits: [
        {
          version: 1,
          "key": 0,
          "value": [],
          "headers": [
            { "text": "1", "value": "value", "type": "custom", "width": "10vw" },
            { "text": "QUALE E' IL TUO LOOK/EFFETTO PREFERITO ?", "value": "type" }
          ],
          "items": [
            {
              "value": 0,
              "type": "VOLUME"
            },
            {
              "value": 1,
              "type": "LISCI"
            },
            {
              "value": 2,
              "type": "RICCI DEFINITI"
            },
            {
              "value": 3,
              "type": "ONDULATI"
            },
            {
              "value": 4,
              "type": "NESSUNA PREFERENZA"
            }
          ]
        },
        {
          "key": 1,
          "value": null,
          "headers": [
            { "text": "2", "value": "value", "type": "custom", "width": "10vw" },
            { "text": "CON QUALE FREQUENZA TI LAVI I CAPELLI ?", "value": "type" }
          ],
          "items": [
            {
              "value": 0,
              "type": "OGNI GIORNO"
            },
            {
              "value": 1,
              "type": "2/3 VOLTE A SETTIMANA"
            },
            {
              "value": 2,
              "type": "UNA VOLTA A SETTIMANA"
            }
          ]
        },
        {
          "key": 2,
          "value": [],
          "headers": [
            { "text": "3", "value": "value", "type": "custom", "width": "10vw" },
            { "text": "QUANTI E QUALI PRODOTTI UTILIZZI A CASA ? (Puoi selezionare più opzioni)", "value": "type" }
          ],
          "items": [
            {
              "value": 0,
              "type": "SHAMPOO"
            },
            {
              "value": 1,
              "type": "BALSAMO"
            },
            {
              "value": 2,
              "type": "MASCHERA"
            },
            {
              "value": 3,
              "type": "PRODOTTO SENZA RISCIACQUO"
            },
            {
              "value": 4,
              "type": "STYLING"
            },
            {
              "value": 5,
              "type": "PIASTRA"
            },
            {
              "value": 6,
              "type": "ALTRO"
            }
          ]
        },
        {
          "key": 3,
          "value": [],
          "headers": [
            { "text": "4", "value": "value", "type": "custom", "width": "10vw" },
            { "text": "DOVE ACQUISTI I PRODOTTI CHE USI A CASA ? (Puoi selezionare più opzioni)", "value": "type" }
          ],
          "items": [
            {
              "value": 0,
              "type": "SUPERMERCATO/NEGOZIO AL DETTAGLIO"
            },
            {
              "value": 1,
              "type": "FARMACIA"
            },
            {
              "value": 2,
              "type": "SALONE"
            }
          ]
        },
        {
          "key": 4,
          "value": null,
          "headers": [
            { "text": "5", "value": "value", "type": "custom", "width": "10vw" },
            { "text": "QUANTO TEMPO SPENDI A CASA PER LO STYLING ?", "value": "type" }
          ],
          "items": [
            {
              "value": 0,
              "type": "< 15 min"
            },
            {
              "value": 1,
              "type": "15 - 30 min"
            },
            {
              "value": 2,
              "type": "30 - 45 min"
            },
            {
              "value": 3,
              "type": "> 45 min"
            }
          ]
        },
        {
          "key": 5,
          "value": null,
          "headers": [
            { "text": "6", "value": "value", "type": "custom", "width": "10vw" },
            { "text": "CON QUALE FREQUENZA CAMBI LOOK ?", "value": "type" }
          ],
          "items": [
            {
              "value": 0,
              "type": "PICCOLI CAMBI UNA VOLTA L'ANNO"
            },
            {
              "value": 1,
              "type": "UN GRANDE CAMBIAMENT ALL'ANNO"
            },
            {
              "value": 2,
              "type": "CAMBI STAGIONALI"
            },
            {
              "value": 3,
              "type": "CAMBIO AD OGNI VISTA"
            }
          ]
        }
      ],
      barberHairCare: [
        {
          version: 1,
          "key": 0,
          "value": [],
          "headers": [
            { "text": "1", "value": "value", "type": "custom", "width": "10vw" },
            { "text": "QUALE E' IL TUO LOOK/EFFETTO PREFERITO ?", "value": "type" },
            { "text": "GRAMMATURA", "value": "textBox", "type": "custom" }
          ],
          "items": [
            {
              "value": 0,
              "type": "SHAMPOO",
              "description": ""
            },
            {
              "value": 2,
              "type": "MASCHERA",
              "description": ""
            },
            {
              "value": 3,
              "type": "PRODOTTO SENZA RISCIACQUO",
              "description": ""
            },
            {
              "value": 4,
              "type": "STYLING",
              "description": ""
            },
            {
              "value": 5,
              "type": "PIASTRA",
              "description": ""
            },
            {
              "value": 6,
              "type": "TRATTAMENTI SPECIFICI CUTE",
              "description": ""
            },
            {
              "value": 7,
              "type": "TRATTAMENTI SPECIFICI CAPELLI",
              "description": ""
            },
            {
              "value": 8,
              "type": "ALTRO",
              "description": ""
            }
          ]
        }
      ],
      barberColoring: [
        {
          version: 2,
          "key": 0,
          "date": null,
          "colorTypeId": null,
          "infoDown": null,
          "value": [],
          "oxValues": [],
          "items": [
            {
              "value": 1,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 2,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 3,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 4,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 5,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 6,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 7,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 8,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 9,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 10,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 0,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            }
          ]
        },
        {
          "key": 1,
          "infoDown": null,
          "colorTypeId": null,
          "value": [],
          "oxValues": [],
          "items": [
            {
              "value": 1,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 2,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 3,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 4,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 5,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 6,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 7,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 8,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 9,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 10,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 0,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            }
          ]
        },
        {
          "key": 2,
          "infoDown": null,
          "colorTypeId": null,
          "value": [],
          "oxValues": [],
          "items": [
            {
              "value": 1,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 2,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 3,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 4,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 5,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 6,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 7,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 8,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 9,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 10,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            },
            {
              "value": 0,
              "checked": false,
              "grammatura": "",
              "colorType": ""
            }
          ]
        },
        {
          "key": 3,
          "infoUp": null,
          "items": [
            {
              "value": 1,
              "description": ""
            },
            {
              "value": 2,
              "description": ""
            },
            {
              "value": 3,
              "description": ""
            },
            {
              "value": 4,
              "description": ""
            }
          ]
        },
        {
          "key": 4,
          "infoUp": null,
          "items": [
            {
              "value": 0,
              "description": ""
            },
            {
              "value": 1,
              "description": ""
            },
            {
              "value": 2,
              "description": ""
            }
          ]
        }
      ],
    }
    this.beautyForm = {
      beautyConfidential: [
        {
          version: 1,
          key: 0,
          title: 'OBIETTIVO',
          items: [
            {
              key: 0,
              type: 'openQuestion',
              value: null,
              question: 'Quale è il suo obiettivo di bellezza?',
            },
          ]
        },
        {
          key: 1,
          title: 'ABITUDINI DI CONSUMO',
          items: [
            {
              key: 0,
              value: null,
              type: 'checkbox',
              question: 'Ha effettuato trattamenti in precedenza?',
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
                { text: 'Manuali', value: 2 },
                { text: 'Tecnologici', value: 3 }
              ],
              textfield: '',
              label: ''
            },
            {
              key: 1,
              value: null,
              type: 'checkbox',
              question: 'Utiizzi Prodotti A Casa?',
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: 'Marca'
            },
            {
              key: 2,
              value: null,
              type: 'checkbox',
              question: 'Dove li acquista maggiormente?',
              checkboxes: [
                { text: 'Profumeria', value: 0 },
                { text: 'Farmacia', value: 1 },
                { text: 'Erboristeria', value: 2 },
                { text: 'Supermercato', value: 3 },
                { text: 'Centro Estetico', value: 4 },
                { text: 'Internet', value: 5 },
                { text: 'Altro', value: 6 },
              ],
              textfield: '',
              label: 'Altro'
            },
          ]
        },
        {
          key: 2,
          title: 'ANAMNESI CLIENTE',
          items: [
            {
              key: 0,
              type: 'openQuestion',
              value: null,
              question: 'Stato di salute attuale',
            },
            {
              key: 1,
              type: 'openQuestion',
              value: null,
              question: 'Eventuali cure in corso',
            },
            {
              key: 2,
              type: 'openQuestion',
              value: null,
              question: 'Precedenti medici o chirurgici',
            },
            {
              key: 3,
              type: 'checkbox',
              value: null,
              question: 'Gravidanza',
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: 'Quante'
            },
            {
              key: 4,
              type: 'checkbox',
              value: null,
              question: 'Allattamento',
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: 'Per Quanto Tempo'
            },
            {
              key: 5,
              type: 'checkbox',
              value: null,
              question: 'Ciclo mestruale',
              checkboxes: [
                { text: 'Regolare', value: 0 },
                { text: 'Irregolare', value: 1 },
                { text: 'Doloroso', value: 2 },
                { text: 'Assente', value: 3 },
              ],
              textfield: '',
              label: 'Causa'
            },
            {
              key: 6,
              type: 'checkbox',
              value: null,
              question: 'Digestione',
              checkboxes: [
                { text: 'Normale', value: 0 },
                { text: 'Lenta', value: 1 }
              ],
              label: 'Quali'
            },
            {
              key: 7,
              type: 'checkbox',
              value: null,
              question: 'Allergie',
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: 'Quali'
            },
            {
              key: 8,
              type: 'checkbox',
              value: null,
              question: 'Intolleranze',
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: 'Quali'
            },
            {
              key: 9,
              type: 'checkbox',
              value: null,
              question: 'Ferite',
              checkboxes: [
                { text: 'Pacemaker', value: 3 },
                { text: 'Ferite', value: 0 },
                { text: 'Protesi', value: 1 },
                { text: 'Cicatrici', value: 2 },
              ],
              textfield: '',
              label: 'Dove'
            },
            {
              key: 10,
              type: 'openQuestion',
              value: null,
              question: 'Patologie Croniche',
              label: 'Quali'
            },
            {
              key: 11,
              type: 'openQuestion',
              value: null,
              question: 'Cure In Corso',
              label: 'Quali'
            },
            {
              key: 12,
              type: 'checkbox',
              value: null,
              question: 'Contraccezione',
              checkboxes: [
                { text: 'Spirale', value: 0 },
                { text: 'Pillola', value: 1 },
                { text: 'Altro', value: 2 }
              ],
              textfield: '',
              label: 'Tipo spirale/Altro'
            },
          ]
        },
        {
          key: 3,
          title: 'ABITUDINI ALIMENTARI',
          items: [
            {
              key: 0,
              type: 'table',
              value: [
                { text: 'Colazione', value: '' },
                { text: 'Pranzo', value: '' },
                { text: 'Cena', value: '' },
                { text: 'Spuntino', value: '' },
                { text: 'Q.tà Acqua', value: '' }
              ],
            },
            {
              key: 1,
              type: 'checkbox',
              value: null,
              question: 'Fumo',
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: 'Quanto'
            },
            {
              key: 2,
              type: 'checkbox',
              value: null,
              question: 'Attività Fisica',
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: 'Quale'
            },
            {
              key: 3,
              type: 'checkbox',
              value: null,
              question: 'Frequenza Attività Fisica',
              checkboxes: [
                { text: '1 volta alla settimana', value: 0 },
                { text: 'Almeno 3 volte alla settimana', value: 1 },
                { text: 'Tutti i giorni', value: 2 },
                { text: 'Altro', value: 3 },
              ],
            },
          ]
        },
        {
          key: 4,
          title: 'ESAME OBIETTIVO',
          items: [
            {
              key: 0,
              type: 'checkbox',
              value: null,
              question: 'Verruche',
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: 'Dove'
            },
            {
              key: 1,
              type: 'checkbox',
              value: null,
              question: 'Micosi Varie',
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: 'Dove'
            },
            {
              key: 2,
              type: 'checkbox',
              value: null,
              question: 'Capillari',
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: 'Dove'
            },
          ]
        },
      ],
      beautyFaceCheckup: [
        {
          version: 1,
          key: 0,
          title: 'GENERALI',
          items: [
            {
              key: 0,
              value: null,
              type: 'checkbox',
              question: 'ISPESSIMENTO',
              checkboxes: [
                { text: 'Leggero', value: 0 },
                { text: 'Medio', value: 1 },
                { text: 'Forte', value: 2 },
              ],
              textfield: '',
              label: 'Dove'
            },
            {
              key: 1,
              value: null,
              type: 'checkbox',
              question: "SENSIBILITA'/COUPEROSE",
              checkboxes: [
                { text: 'Leggero', value: 0 },
                { text: 'Medio', value: 1 },
                { text: 'Forte', value: 2 },
              ],
              textfield: '',
              label: 'Marca'
            },
            {
              key: 2,
              value: null,
              type: 'checkbox',
              question: "IMPURITA'",
              checkboxes: [
                { text: 'Leggero', value: 0 },
                { text: 'Medio', value: 1 },
                { text: 'Forte', value: 2 },
              ],
              textfield: '',
              label: 'Marca'
            },
            {
              key: 3,
              value: null,
              type: 'checkbox',
              question: "ACNE",
              checkboxes: [
                { text: 'Volgare', value: 0 },
                { text: 'Rosacea', value: 1 },
                { text: 'Cistica', value: 2 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 4,
              value: null,
              type: 'checkbox',
              question: "LUCIDITA'",
              checkboxes: [
                { text: 'Leggero', value: 0 },
                { text: 'Medio', value: 1 },
                { text: 'Forte', value: 2 },
              ],
              textfield: '',
              label: 'Marca'
            },
            {
              key: 5,
              value: null,
              type: 'checkbox',
              question: "DETTAGLIO LUCIDITA'",
              checkboxes: [
                { text: 'Zona T', value: 0 },
                { text: 'Acne Seborroica', value: 1 },
                { text: 'Altro', value: 2 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 6,
              value: null,
              type: 'checkbox',
              question: "RUGOSITA'",
              checkboxes: [
                { text: 'Leggero', value: 0 },
                { text: 'Medio', value: 1 },
                { text: 'Forte', value: 2 },
              ],
              textfield: '',
              label: 'Marca'
            },
            {
              key: 7,
              value: null,
              type: 'checkbox',
              question: "SVUOTAMENTO",
              checkboxes: [
                { text: 'Leggero', value: 0 },
                { text: 'Medio', value: 1 },
                { text: 'Forte', value: 2 },
              ],
              textfield: '',
              label: 'Marca'
            },
            {
              key: 8,
              value: null,
              type: 'checkbox',
              question: "DISIDRATAZIONE",
              checkboxes: [
                { text: 'Leggero', value: 0 },
                { text: 'Medio', value: 1 },
                { text: 'Forte', value: 2 },
              ],
              textfield: '',
              label: 'Marca'
            },
            {
              key: 9,
              value: null,
              type: 'checkbox',
              question: "CEDIMENTO",
              checkboxes: [
                { text: 'Leggero', value: 0 },
                { text: 'Medio', value: 1 },
                { text: 'Forte', value: 2 },
              ],
              textfield: '',
              label: 'Marca'
            },
            {
              key: 10,
              value: null,
              type: 'checkbox',
              question: "CON CLOASMI",
              checkboxes: [
                { text: 'Leggero', value: 0 },
                { text: 'Medio', value: 1 },
                { text: 'Forte', value: 2 },
              ],
              textfield: '',
              label: 'Marca'
            },
            {
              key: 11,
              value: null,
              type: 'checkbox',
              question: "CON MELASMI",
              checkboxes: [
                { text: 'Leggero', value: 0 },
                { text: 'Medio', value: 1 },
                { text: 'Forte', value: 2 },
              ],
              textfield: '',
              label: 'Marca'
            },
          ],
          textField: '',
        },
        {
          key: 1,
          title: "NECESSITA' RILEVATE",
          items: [
            {
              key: 0,
              value: null,
              type: 'checkbox',
              question: '',
              checkboxes: [
                { text: 'RIATTIVARE', value: 0 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 1,
              value: null,
              type: 'checkbox',
              question: '',
              checkboxes: [
                { text: 'RAFFORZARE', value: 1 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 2,
              value: null,
              type: 'checkbox',
              question: '',
              checkboxes: [
                { text: 'RIEQUILIBRARE', value: 2 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 3,
              value: null,
              type: 'checkbox',
              question: '',
              checkboxes: [
                { text: 'IDRATARE', value: 3 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 4,
              value: null,
              type: 'checkbox',
              question: '',
              checkboxes: [
                { text: 'RINGIOVANIRE', value: 4 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 5,
              value: null,
              type: 'checkbox',
              question: '',
              checkboxes: [
                { text: 'LIFTARE', value: 5 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 6,
              value: null,
              type: 'checkbox',
              question: '',
              checkboxes: [
                { text: 'RIEMPIRE', value: 6 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 7,
              value: null,
              type: 'checkbox',
              question: '',
              checkboxes: [
                { text: 'RIGENERARE', value: 7 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 8,
              value: null,
              type: 'checkbox',
              question: '',
              checkboxes: [
                { text: 'ILLUMINARE/ANTI-MACCHIA', value: 8 },
              ],
              textfield: '',
              label: ''
            },
          ]
        },
      ],
      beautyBodyCheckup: [
        {
          version: 1,
          key: 0,
          title: 'GENERALI',
          items: [
            {
              key: 0,
              value: null,
              type: 'checkbox',
              question: 'PESO',
              checkboxes: [
                { text: 'Stabile', value: 0 },
                { text: 'Soggetto A Frequenti Modifiche', value: 1 },
              ],
              textfield: '',
              label: 'Peso'
            },
            {
              key: 1,
              value: null,
              type: 'openQuestion',
              question: "Sovrappeso Effettivo",
              textfield: '',
              label: '',
            },
            {
              key: 2,
              value: null,
              type: 'openQuestion',
              question: "Altezza",
              textfield: '',
              label: ''
            },
            {
              key: 3,
              value: null,
              type: 'checkbox',
              question: "Rilassamento Muscolare",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: 'Dove'
            },
            {
              key: 4,
              value: null,
              type: 'checkbox',
              question: "Rilassamento Tissutuale",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: 'Dove'
            },
            {
              key: 5,
              value: null,
              type: 'checkbox',
              question: "Presenza Di Smagliature",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: 'Dove'
            },
            {
              key: 6,
              value: null,
              type: 'checkbox',
              question: "Ritenzione Dei Liquidi",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: 'Dove'
            },
            {
              key: 7,
              value: null,
              type: 'checkbox',
              question: "Presenza Di Cellulite",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: 'Dove'
            },
            {
              key: 8,
              value: null,
              type: 'checkbox',
              question: "Edematosa",
              checkboxes: [
                { text: 'I/II Stadio', value: 0 },
                { text: 'III/IV Stadio', value: 1 },
              ],
              textfield: '',
              label: 'Dove'
            },
            {
              key: 9,
              value: null,
              type: 'checkbox',
              question: "Compatta",
              checkboxes: [
                { text: 'I/II Stadio', value: 0 },
                { text: 'III/IV Stadio', value: 1 },
              ],
              textfield: '',
              label: 'Dove'
            },
            {
              key: 10,
              value: null,
              type: 'checkbox',
              question: "Molle",
              checkboxes: [
                { text: 'I/II Stadio', value: 0 },
                { text: 'III/IV Stadio', value: 1 },
              ],
              textfield: '',
              label: 'Dove'
            },
            {
              key: 11,
              value: null,
              type: 'checkbox',
              question: "Al tatto la zona si presenta",
              checkboxes: [
                { text: 'Fredda', value: 0 },
              ],
              textfield: '',
              label: 'Dove'
            },
            {
              key: 12,
              value: null,
              type: 'checkbox',
              question: "                            ",
              checkboxes: [
                { text: 'Normale', value: 0 },
              ],
              textfield: '',
              label: 'Dove'
            },
            {
              key: 13,
              value: null,
              type: 'checkbox',
              question: "                            ",
              checkboxes: [
                { text: 'Calda', value: 0 },
              ],
              textfield: '',
              label: 'Dove'
            },
            {
              key: 14,
              value: null,
              type: 'checkbox',
              question: "                            ",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: 'Dove'
            },
            {
              key: 16,
              value: null,
              type: 'checkbox',
              question: "Adipe In Eccesso",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: 'Dove'
            },
          ]
        },
        {
          key: 1,
          title: "NECESSITA' RILEVATE",
          items: [
            {
              key: 0,
              value: null,
              type: 'checkbox',
              question: '',
              checkboxes: [
                { text: 'RIATTIVARE', value: 0 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 1,
              value: null,
              type: 'checkbox',
              question: '',
              checkboxes: [
                { text: 'DRENARE', value: 0 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 2,
              value: null,
              type: 'checkbox',
              question: '',
              checkboxes: [
                { text: 'SCIOGLIERE', value: 0 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 3,
              value: null,
              type: 'checkbox',
              question: '',
              checkboxes: [
                { text: 'RASSODARE', value: 0 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 4,
              value: null,
              type: 'checkbox',
              question: '',
              checkboxes: [
                { text: 'RIDURRE', value: 0 },
              ],
              textfield: '',
              label: ''
            },
          ]
        },
        {
          key: 2,
          title: "MISURE CORPO",
          items: [
            {
              key: 0,
              value: null,
              type: 'bodyMeasures',
              question: '',
              cmMeasures: ['', '', '', '', '', '', '', '', ''],
              arms: '',
              chest: '',
              waist: '',
              wrist: '',
              flanks: '',
              thigh: '',
              knee: '',
              calf: '',
              ankle: '',
            },
          ]
        },
      ],
      beautyEpilation: [
        {
          version: 1,
          key: 0,
          title: 'QUESTIONARIO EPILAZIONE',
          items: [
            {
              key: 0,
              value: null,
              type: 'checkbox',
              question: 'Ha già effettuato trattamenti di epilazione progressiva permamente',
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: 'Quali aree ha trattato'
            },
            {
              key: 1,
              value: null,
              type: 'openQuestion',
              question: "Che tipo di risultati ha ottenuto?",
              textfield: '',
              label: '',
            },
            {
              key: 2,
              value: null,
              type: 'openQuestion',
              question: "Quali metodi usa abitualmente per depilarsi?",
              textfield: '',
              label: ''
            },
            {
              key: 3,
              value: null,
              type: 'checkbox',
              question: "Sta utilizzando farmaci quali antibiotici, cortisonici, pillola anticoncezionale o farmaci per la ricrescita dei capelli?",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: 'Quali'
            },
            {
              key: 4,
              value: null,
              type: 'checkbox',
              question: "E' in gravidanza o allattamento?",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 5,
              value: null,
              type: 'checkbox',
              question: "Ha un ciclo mestruale regolare?",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 6,
              value: null,
              type: 'checkbox',
              question: "Ha squilibri ormonali?",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 7,
              value: null,
              type: 'checkbox',
              question: "E' portatore/trice di Peacemaker",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 8,
              value: null,
              type: 'checkbox',
              question: "Ha avuto Melanoma? (Lei o parenti di primo grado)",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 9,
              value: null,
              type: 'checkbox',
              question: "Ha Lupus o Sclerodermie?",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 10,
              value: null,
              type: 'checkbox',
              question: "Soffre di altre Patologie?",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: 'Quali'
            },
            {
              key: 11,
              value: null,
              type: 'openQuestion',
              question: "Quale zona le interessa trattare?",
              textfield: '',
              label: 'Dove'
            },
            {
              key: 12,
              value: null,
              type: 'checkbox',
              question: "Tipo di pelo nell'area da trattare",
              checkboxes: [
                { text: 'Pelo Sottile', value: 0 },
                { text: 'Pelo Medio', value: 1 },
                { text: 'Pelo Spesso', value: 2 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 13,
              value: null,
              type: 'checkbox',
              question: "Valutazione della densità pilifera:",
              checkboxes: [
                { text: 'Lieve', value: 0 },
                { text: 'Moderata', value: 1 },
                { text: 'Eccessiva', value: 2 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 14,
              value: null,
              type: 'openQuestion',
              question: "Individua eventuali anomalie di distribuzione/densità pilifera: ",
              textfield: '',
              label: ''
            },
            {
              key: 15,
              value: null,
              type: 'openQuestion',
              question: "Colore del pelo nell'area da trattare ",
              textfield: '',
              label: ''
            },
            {
              key: 16,
              value: null,
              type: 'checkbox',
              question: "Peli annessi:",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 17,
              value: null,
              type: 'checkbox',
              question: "Cosmetici autocura consigliati",
              checkboxes: [
                { text: 'CREMA RINNOVANTE viso e corpo', value: 0 },
                { text: 'FLUIDO DELICATO LENITIVO viso', value: 1 },
                { text: 'FLUIDO DELICATO LENITIVO corpo', value: 2 },
                { text: 'DEODORANTE SPRAY LENITIVO corpo', value: 3 },
              ],
              textfield: '',
              label: ''
            },
          ]
        },
      ],
      beautyExtention: [
        {
          version: 1,
          key: 0,
          title: 'QUESTIONARIO EXTENTION',
          items: [
            {
              key: 0,
              value: null,
              type: 'checkbox',
              question: "HAI GIA' FATTO EXTENTION CIGLIA?",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 1,
              value: null,
              type: 'checkbox',
              question: "HAI FATTO PERMANENTE CIGLIA ULTIMAMENTE?",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 2,
              value: null,
              type: 'checkbox',
              question: "SOFFRI DI DERMATITE O ECZEMI O ALLERGIE?",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 3,
              value: null,
              type: 'checkbox',
              question: "STAI FACENDO CHEMIOTERAPIA?",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 4,
              value: null,
              type: 'checkbox',
              question: "SEI IN STATO DI GRAVIDANZA?",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 5,
              value: null,
              type: 'checkbox',
              question: "SEI SENSIBILE NELLA ZONA OCCHI E PALPEBRE?",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: ''
            },
            {
              key: 6,
              value: null,
              type: 'checkbox',
              question: "PORTI OCCHIALI O LENTI?",
              checkboxes: [
                { text: 'No', value: 0 },
                { text: 'Si', value: 1 },
              ],
              textfield: '',
              label: ''
            },
          ],
          textfield: ['', ''],
        },
      ],
      beautyNails: [
        {
          version: 1,
          key: 0,
          items: [
            {
              key: 0,
              value: {
                0: { month: '', notes: '' },
                1: { month: '', notes: '' },
                2: { month: '', notes: '' },
                3: { month: '', notes: '' },
              },
              type: 'custom',
            },
          ]
        },
        {
          version: 1,
          key: 1,
          items: [
            {
              key: 0,
              label: 'Note Organizzative',
              value: ''
            },
            {
              key: 1,
              label: 'Note Preferenziali',
              value: ''
            },
            {
              key: 2,
              label: 'Note Tecniche',
              value: ''
            },
          ]
        }
      ],
      beautyWorks: [
        {
          version: 1,
          key: 0,
          items: [
            {
              key: 0,
              values: {
                body: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},],
                face: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},],
                general: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},],
              }
            },
          ]
        }
      ]
    }
  }

  getBarberForm() {
    return this.barberForm
  }
}

export default FormsDataSheetJson
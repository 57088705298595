var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"25px"}},[_c('div',{staticStyle:{"width":"35%"}},_vm._l((_vm.infoTabs),function(item,indexInfoTab){return _c('div',{key:item.key},[_c('div',{staticStyle:{"color":"red","font-size":"20px"}},[_vm._v(_vm._s(item.title)+" ")]),_vm._l((item.items),function(element,indexInfoTabElement){return _c('div',{key:element.key},[(element.type == 'openQuestion')?_c('OpenQuestion',{attrs:{"question":element.question,"value":element.value,"label":element.label},on:{"valueChanged":($event) => {
            _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].value = $event
            _vm.updateCustomerForm()
          }}}):(element.type == 'checkbox')?_c('CheckBoxQuestion',{attrs:{"value":element.value,"question":element.question,"textfield":element.textfield,"checkboxes":element.checkboxes},on:{"checkboxClicked":($event) => {
              _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].value = $event.value
              _vm.updateCustomerForm()
            },"textfieldChanged":($event) => {
              _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].textfield = $event
              _vm.updateCustomerForm()
            }}}):(element.type == 'table')?_c('TableQuestion',{attrs:{"items":element.value}}):_vm._e()],1)})],2)}),0),(_vm.infoTabs.length > 0)?_c('table',{staticClass:"extentionTable",staticStyle:{"width":"60%"}},[_c('tr',[_c('td',{staticStyle:{"width":"50%"}},[_c('v-img',{attrs:{"src":require('@/assets/beautyEyes.png'),"contain":"","gradient":"to bottom, rgba(0,0,0,0), rgba(0,0,0,0.2)","max-width":"30vw","min-height":"21vh"}})],1),_c('td',[_c('v-textarea',{attrs:{"label":"Ciglia","color":"grey","rows":"3","hide-details":"auto"},on:{"input":_vm.updateCustomerForm},model:{value:(_vm.infoTabs[0].textfield[0]),callback:function ($$v) {_vm.$set(_vm.infoTabs[0].textfield, 0, $$v)},expression:"infoTabs[0].textfield[0]"}}),_c('v-textarea',{attrs:{"label":"Sottociglia","color":"grey","rows":"3","hide-details":"auto"},on:{"input":_vm.updateCustomerForm},model:{value:(_vm.infoTabs[0].textfield[1]),callback:function ($$v) {_vm.$set(_vm.infoTabs[0].textfield, 1, $$v)},expression:"infoTabs[0].textfield[1]"}})],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';
import colorLinesCache from "./colorLines.cache";

class colorLinesManagment {
  constructor() {
    this._alreadyLoaded = false
  }

  async cachedList(filters) {
    let localFilters = !!filters ? filters : {}
    if (!this._alreadyLoaded) {
      await colorLinesCache.deleteAllCache()
      this._alreadyLoaded = true
    }

    if (await colorLinesCache.cachePresent()) {
      return await colorLinesCache.get()
    } else {
      await this.reloadCache(localFilters)
      return await colorLinesCache.get()
    }
  }

  async reloadCache(filters) {
    const colorLines = await this.list(1, 1000, filters)
    await colorLinesCache.cache({ colorLines: colorLines.rows })
  }

  setAlreadyLoaded(value) {
    this._alreadyLoaded = value
  }

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/colorLines/list', {
        page: page,
        rowPerPage: rowPerPage,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  create(colorLine) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/colorLines/create', colorLine).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  update(colorLine) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/colorLines/update', colorLine).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  delete(colorLine) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!colorLine.id) {
        reject('id undefined')
      }

      axios.post(apiUrl + '/registries/colorLines/archive', {
        id: colorLine.id
      }).then((response) => {
        if (!response.success) {
          reject(response.results)
        } else {
          resolve(response)
        }
      })
    })
  }

  get(colorLineId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/registries/colorLines/get', { id: colorLineId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }


  fields() {
    return Promise.resolve([
      { text: "Nome", value: "name" },
    ])
  }

  _fieldsToExport() {
    return {
      'Nome': 'name',
    }
  }
}

export default new colorLinesManagment();

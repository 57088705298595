import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import billsService from "../bills/bills.service";

class customerCardsService {
  constructor() {

  }

  deactivate(customerCard) { 
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if(!customerCard || !customerCard.id) {
        reject(new Error('customer card is not specified'))
        return
      }

      customerCard['status'] = 'notActive'

      axios.post(apiUrl + '/registries/customers/customerCards/update', customerCard).then(async (response) => {
        if(response.success) {
          await billsService.reloadAllTodayCachedFilterList()
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  activate(customer, customerCard) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!customerCard || !customerCard.number) {
        reject(new Error('customer card is not specified'))
        return
      } else if(!customer || !customer.id) {
        reject(new Error('customer is not specified'))
        return
      }

      customerCard['status'] = 'active'

      axios.post(apiUrl + '/registries/customers/customerCards/create', {
        customerCard: customerCard,
        customer: customer
      }).then(async (response) => {
        if (response.success) {
          await billsService.reloadAllTodayCachedFilterList()
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  update(customerCard) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if(!customerCard || !customerCard.id) {
        reject(new Error('customer card is not specified'))
        return
      }

      customerCard.status = undefined
      customerCard.customerId = undefined

      axios.post(apiUrl + '/registries/customers/customerCards/update', customerCard).then(async (response) => {
        if (response.success) {
          await billsService.reloadAllTodayCachedFilterList()
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  get(customerCard) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!customerCard || !customerCard.id) {
        reject(new Error('customer card is not specified'))
        return
      }

      
      axios.get(apiUrl + '/registries/customers/customerCards/get', customerCard).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  transact(customerCard, cashDesk, amount, infos) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!customerCard || !customerCard.id) {
        reject(new Error('customer card is not specified'))
        return
      } else if(!cashDesk || !cashDesk.id) {
        reject(new Error('cash desk is not specified'))
        return
      } else if(!amount) {
        reject(new Error('amount should specify'))
        return
      }

      axios.post(apiUrl + '/payments/transactions/customerCards/transact', {
        object: customerCard,
        date: new Date(),
        amount: amount,
        documentType: !!infos ? infos.documentType : undefined,
        cashPaid: !!infos ? infos.cashPaid : undefined,
        cardPaid: !!infos ? infos.cardPaid : undefined,
        checkPaid: !!infos ? infos.checkPaid : undefined,
        otherPaid: !!infos ? infos.otherPaid : undefined,
        notPaid: !!infos ? infos.notPaid : undefined,
        ghostPaid: !!infos ? infos.ghostPaid : undefined,
        discounts: !!infos ? infos.discounts : undefined,
        info: {
          customers: !!infos ? [infos.customer] : [],
          cashDesk: {
            id: cashDesk.id,
            name: cashDesk.name
          },
          remaining: Number(!!Number(customerCard.remaining) ? Number(customerCard.remaining) + Number(amount) : 0).toFixed(2),
          documentNumber: !!infos ? infos.documentNumber : undefined
        },
        cashDeskLog: cashDesk.cashDeskLog.id,
      }).then(async (response) => {
        if (response.success) {
          await billsService.reloadAllTodayCachedFilterList()
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  checkActiveCardToCustomer(customerId) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/registries/customers/customerCards/checkActiveCardToCustomer', { id: customerId}).then((response) => {
        resolve(response.results)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}

export default new customerCardsService()
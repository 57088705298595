var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.infoTabs),function(item,indexInfoTab){return _c('div',{key:item.key},[_c('div',{staticStyle:{"color":"red","font-size":"20px"}},[_vm._v(_vm._s(item.title)+" ")]),_vm._l((item.items),function(element,indexInfoTabElement){return _c('div',{key:element.key},[(element.type == 'openQuestion')?_c('OpenQuestion',{attrs:{"question":element.question,"value":element.value,"label":element.label},on:{"valueChanged":($event) => {
            _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].value = $event
            _vm.updateCustomerForm()
          }}}):(element.type == 'checkbox')?_c('CheckBoxQuestion',{attrs:{"value":element.value,"question":element.question,"checkboxes":element.checkboxes,"textfield":element.textfield,"label":element.label},on:{"checkboxClicked":($event) => {
            _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].value = $event.value
            _vm.updateCustomerForm()
          },"textfieldChanged":($event) => {
            _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].textfield = $event
            _vm.updateCustomerForm()
          }}}):(element.type == 'table')?_c('TableQuestion',{attrs:{"items":element.value},on:{"valueChanged":($event) => {
            _vm.infoTabs[indexInfoTab].items[indexInfoTabElement].value = $event
            _vm.updateCustomerForm()
          }}}):_vm._e()],1)})],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card flat color="transparent" height="100%">
    <v-navigation-drawer color="transparent" floating permanent>
      <v-list-item-group>
        <v-list dense nav>
          <template v-for="item in items">
            <v-list-item
              v-if="!item.children"
              :key="item[identifierKey]"
              link
              :class="{
                'active': !!identifierKey && item[identifierKey] && value[identifierKey] == item[identifierKey]
              }"
              @click="$emit('item-click', item)"
            >
              <v-list-item-icon>
                <v-icon :color="item.color">{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-else
              :key="item.title"
              no-action
              :value="item.children.map(el => el[identifierKey]).includes(value[identifierKey])"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{item.title}}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="subItem in item.children"
                :key="subItem[identifierKey]"
                :class="{
                  'active': !!identifierKey && subItem[identifierKey] && value[identifierKey] == subItem[identifierKey]
                }"
                @click="$emit('item-click', subItem)"
              >
                <v-list-item-icon>
                  <v-icon v-if="subItem.color" color="#ff0000">{{ subItem.icon }}</v-icon>
                  <v-icon v-else>{{ subItem.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-divider v-if="divided" :key="item[identifierKey] + '-divider'" class="mb-1"></v-divider>
          </template>
        </v-list>
      </v-list-item-group>
    </v-navigation-drawer>
  </v-card>
</template>

<script>

export default {
  name: "LeftSideMenu",
  components: {},
  data() {
    return {
    };
  },
  mounted() {
  },
  props: {
    items: {
      type: Array,
      default: function () {
        return [ ];
      },
    },
    identifierKey: {
      type: String,
      default: 'id'
    },
    divided: {
      type: Boolean,
      default: false
    },
    value: {}
  },
};
</script>

<style scoped>
.active {
  background: rgba(160, 153, 153, 0.39);
}
</style>